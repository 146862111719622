import React from "react";
import SearchInput from "../FormElements/SearchInput";
import IconifyIcon from "../IconifyIcon";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import "./desktop-filters.css";
import DatePickerCustom from "../FormElements/DatePickerCustom";
import { Dayjs } from "dayjs";
import { CSVLink } from "react-csv";

export interface DesktopFiltersProps {
  filterData:
    | {
        type:
          | "search"
          | "date"
          | "multidate"
          | "selector"
          | "autocomplete"
          | "icon"
          | "function";
        value: any;
        setValue: (value: any) => void;
        disabled: boolean;
        tooltip: string;
        function: () => void;
        array: { label: string; value: any }[];
        size: "small" | "large";
        icon: string;
        label: string;
        multiDateValue: Dayjs | null;
        setMultiDateValue: (multiDateValue: Dayjs | null) => void;
        filterWidth?: string | number;
        downloadable?: boolean;
        downloadHeader?: any[];
        downloadData?: any[];
        downloadFilename?: string;
      }[]
    | null;
}

const DesktopFilters = ({ filterData }: DesktopFiltersProps) => {
  return (
    <div
      className="w-100 border-box flex align-items-center justify-content-end px-8 gap-8 flex-nowrap scroll"
      style={{ height: 60, backgroundColor: "white" }}
    >
      {filterData &&
        filterData.map((item, index) => (
          <div
            key={`${index}-${item?.type}`}
            className={`flex align-items-center ${
              item?.type !== "icon" && item?.type !== "function"
                ? "flex-grow-1"
                : ""
            }`}
            style={{
              maxWidth: item?.filterWidth ? item?.filterWidth : "",
              minWidth: item?.filterWidth ? item?.filterWidth : "",
            }}
          >
            {item?.type === "search" && (
              <SearchInput
                value={item?.value}
                setValue={item?.setValue}
                size={item?.size}
              />
            )}
            {item?.type === "function" && (
              <>
                {item.tooltip ? (
                  <Tooltip
                    disableInteractive
                    title={item?.tooltip}
                    arrow
                    placement="top"
                  >
                    <div
                      className="flex align-items-center"
                      style={{ height: item?.size === "small" ? 40 : 56 }}
                    >
                      {item?.downloadable ? (
                        <CSVLink
                          data={item?.downloadData ? item?.downloadData : []}
                          headers={item?.downloadHeader}
                          filename={item?.downloadFilename}
                        >
                          <IconifyIcon
                            icon={item?.icon}
                            className="primary-color font-28 pointer"
                            onClick={item?.function}
                          />
                        </CSVLink>
                      ) : (
                        <IconifyIcon
                          icon={item?.icon}
                          className="primary-color font-28 pointer"
                          onClick={item?.function}
                        />
                      )}
                    </div>
                  </Tooltip>
                ) : (
                  <div
                    className="flex align-items-center"
                    style={{ height: item?.size === "small" ? 40 : 56 }}
                  >
                    <IconifyIcon
                      icon={item?.icon}
                      className="primary-color font-28 pointer"
                      onClick={item?.function}
                    />
                  </div>
                )}
              </>
            )}
            {item?.type === "icon" && (
              <>
                {item.tooltip ? (
                  <Tooltip
                    disableInteractive
                    title={item?.tooltip}
                    arrow
                    placement="top"
                  >
                    <div
                      className="flex align-items-center"
                      style={{ height: item?.size === "small" ? 40 : 56 }}
                    >
                      <IconifyIcon
                        icon={item?.icon}
                        className={`icon-filter font-20 pointer ${
                          item?.value === item?.label ? "selected" : ""
                        }`}
                        onClick={() => {
                          if (item?.value === item?.label) {
                            item?.setValue("");
                          } else {
                            item?.setValue(item?.label);
                          }
                        }}
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <div
                    className="flex align-items-center"
                    style={{ height: item?.size === "small" ? 40 : 56 }}
                  >
                    <IconifyIcon
                      icon={item?.icon}
                      className={`icon-filter font-20 pointer ${
                        item?.value === item?.label ? "selected" : ""
                      }`}
                      onClick={() => {
                        if (item?.value === item?.label) {
                          item?.setValue("");
                        } else {
                          item?.setValue(item?.label);
                        }
                      }}
                    />
                  </div>
                )}
              </>
            )}
            {item?.type === "selector" && (
              <FormControl fullWidth>
                <Select
                  labelId={item?.label}
                  id={item?.label}
                  value={item?.value}
                  onChange={(event: SelectChangeEvent) =>
                    item?.setValue(event.target.value as string)
                  }
                  size="small"
                >
                  {item.array.map((item, index) => (
                    <MenuItem
                      key={`${index}-${item?.label}`}
                      value={item?.value}
                    >
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {item?.type === "multidate" && (
              <div className="w-100 flex align-items-center gap-8">
                <FormControl fullWidth>
                  <Select
                    labelId={item?.label}
                    id={item?.label}
                    value={item?.value}
                    onChange={(event: SelectChangeEvent) =>
                      item?.setValue(event.target.value as string)
                    }
                    size="small"
                  >
                    {item.array.map((item, index) => (
                      <MenuItem
                        key={`${index}-${item?.label}`}
                        value={item?.value}
                      >
                        {item?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {item?.type === "multidate" && item.value === "calendar" && (
                  <DatePickerCustom
                    value={item?.multiDateValue}
                    setValue={item?.setMultiDateValue}
                  />
                )}
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default DesktopFilters;
