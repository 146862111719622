import React from "react";
import "./desktop-header.css";
import { useAppSelector } from "../../app/hooks";
import { resetUser, selectUser } from "../../slices/user";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetToken } from "../../slices/token";
import UserModal from "../Modals/UserModal";

interface DesktopHeaderProps {
  title: string;
}

const DesktopHeader = ({ title }: DesktopHeaderProps) => {
  const user = useAppSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showUserDetails, setShowUserDetails] = React.useState<boolean>(false);

  const userInitial = user?.username.charAt(0);

  const onLogout = () => {
    dispatch(resetToken());
    dispatch(resetUser());
    navigate("/");
  };

  return (
    <div className="desktop-header-container px-8">
      <UserModal
        showUserDetails={showUserDetails}
        setShowUserDetails={setShowUserDetails}
        onLogout={onLogout}
      />
      <div
        className="w-100 border-box flex align-items-center justify-content-between"
        style={{ height: "60px" }}
      >
        <p className="main-title">{title}</p>
        <div className="user-container">
          <p className="body-text-md pr-6 pl-2">{user?.email}</p>
          <div
            className="user-initial-container"
            onClick={() => setShowUserDetails(true)}
          >
            <p style={{ color: "white", fontWeight: 400, fontSize: 18 }}>
              {userInitial}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopHeader;
