import React from "react";
import { useAppSelector } from "../../../app/hooks";
import { selectLabels } from "../../../slices/language";
import { Checkbox, TextField } from "@mui/material";
import { selectUser } from "../../../slices/user";

interface NewInvitationVisitDetailsProps {
  motive: string;
  setMotive: (motive: string) => void;
  multipleAccess: boolean;
  antipassback: boolean;
  setAntipassback: (antipassback: boolean) => void;
  selectedAccessPoints: string[];
  setSelectedAccessPoints: (selectedAccessPoints: string[]) => void;
}

const NewInvitationVisitDetails = ({
  motive,
  setMotive,
  multipleAccess,
  antipassback,
  setAntipassback,
  selectedAccessPoints,
  setSelectedAccessPoints,
}: NewInvitationVisitDetailsProps) => {
  const labels = useAppSelector(selectLabels);
  const user = useAppSelector(selectUser);

  const accessPointsArray =
    user?.access_points.map((item) => {
      return {
        name: item.name,
        venue: item.vanue_name,
        value: `${item.id}`,
      };
    }) || [];

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setState: (state: boolean) => void
  ) => {
    setState(event.target.checked);
  };

  return (
    <div
      className="w-100 border-box hidden flex flex-dir-col align-items-center gap-6 p-8"
      style={{
        backgroundColor: "white",
        border: "1px #e0e0e0 solid",
        borderRadius: 6,
      }}
    >
      <div className="w-100">
        <p className="t-nowrap body-text-lg font-w-500 title-color mb-4">
          {labels.visit_details}
        </p>
      </div>
      <div className="w-100 border-box flex align-items-start gap-10">
        <div className="w-50 flex flex-dir-col gap-6">
          <div>
            <p className="t-nowrap body-text-xs mb-3">{labels.motive} *</p>
            <TextField
              fullWidth
              placeholder={labels.reason_for_visit}
              value={motive}
              size="small"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setMotive(event.target.value);
              }}
            />
          </div>
          {multipleAccess && (
            <div className="flex align-items-center justify-content-start gap-2">
              <Checkbox
                checked={antipassback}
                onChange={(e) => handleChange(e, setAntipassback)}
              />
              <p className="body-text-md">{labels.antipassback}</p>
            </div>
          )}
        </div>
        <div className="w-50">
          <p className="t-nowrap body-text-xs mb-3">
            {labels.allowed_access_points} *
          </p>
          <div className="flex flex-dir-col gap-4 access-points-container">
            {accessPointsArray.map((item, index) => (
              <div
                key={`${index}-${item?.value}`}
                className="w-100 flex align-items-center gap-2"
              >
                <Checkbox
                  checked={selectedAccessPoints.includes(item?.value)}
                  onChange={() => {
                    const dupSelAccessPoints = [...selectedAccessPoints];
                    if (dupSelAccessPoints.includes(item?.value)) {
                      const indexValue = dupSelAccessPoints.indexOf(
                        item?.value
                      );
                      dupSelAccessPoints.splice(indexValue, 1);
                    } else {
                      dupSelAccessPoints.push(item?.value);
                    }
                    setSelectedAccessPoints(dupSelAccessPoints);
                  }}
                />
                <div>
                  <p className="t-nowrap body-text-sm">{item?.name}</p>
                  <p className="t-nowrap body-text-xs title-color">
                    {item?.venue}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewInvitationVisitDetails;
