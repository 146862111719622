import React from "react";
import DesktopHeader from "../../components/DesktopHeader";
import { useAppSelector, useDebounce } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import DesktopBody from "../../components/DesktopBody";
import { DesktopFiltersProps } from "../../components/DesktopFilters/DesktopFilters";
import { CircularProgress } from "@mui/material";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import api from "../../api";
import "./access-points.css";
import TableComponent from "../../components/TableComponent";
import { TableData } from "../../components/TableComponent/TableComponent";
import DesktopDropView from "../../components/DesktopDropView";
import DesktopAccessPointDetails from "../../components/DesktopAccessPointDetails";
import { selectUser, selectUserRole } from "../../slices/user";
import { PropertyArray } from "../../api/models/property-model";
import dayjs from "dayjs";

const AccessPoints = () => {
  const labels = useAppSelector(selectLabels);
  const userRole = useAppSelector(selectUserRole);
  const user = useAppSelector(selectUser);

  const companyId = user?.company?.id;

  const googleAPIKey = "AIzaSyCxuo6aFRHzt74w5hEeiHcTEpL40TzOaXk";

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleAPIKey,
  });

  const mapStyles = {
    height: "100%",
    width: "100%",
  };

  const headers = [
    { label: "ID", key: "ID" },
    { label: labels.name, key: labels.name },
    { label: labels.location, key: labels.location },
    { label: labels.address, key: labels.address },
    { label: labels.gates, key: labels.gates },
    { label: labels.time_zone, key: labels.time_zone },
    { label: labels.latitude, key: labels.latitude },
    { label: labels.longitude, key: labels.longitude },
  ];

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [searchFilter, setSearchFilter] = React.useState<string>("");
  const [coordinates, setCoordinates] = React.useState<any>([]);
  const [map, setMap] = React.useState<any>(null);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalInvitations, setTotalInvitations] = React.useState<number>(0);
  const [tableDataToDisplay, setTableDataToDisplay] =
    React.useState<TableData | null>(null);
  const [selectedAccessPointId, setSelectedAccessPointId] = React.useState<
    number | null
  >(null);
  const [showAccessPointDetails, setShowAccessPointDetails] =
    React.useState<boolean>(false);
  const [properties, setProperties] = React.useState<PropertyArray | null>(
    null
  );
  const [selectedProperty, setSelectedProperty] = React.useState<string | null>(null);
  const [propertiesFullData, setPropertiesFullData] =
    React.useState<PropertyArray | null>(null);
  const [dataTable, setDataTable] = React.useState<any[]>([]);

  const debouncedSearchText = useDebounce(searchFilter, 500);

  const propertiesArray = properties
    ? properties.data.map((item) => {
        return {
          label: item?.attributes?.name || "",
          value: `${item.id}`,
        };
      })
    : [];

  const fixedPage = React.useMemo(() => {
    return page + 1;
  }, [page]);

  const selectedAccessPointName =
    tableDataToDisplay?.body.filter(
      (item) => item?.row === selectedAccessPointId
    )[0]?.cells[0]?.label[0]?.mainLabel || "";

  const currentDate = dayjs().format("DD-MM-YY");

  const selectedPropertyName =
    propertiesFullData?.data.filter(
      (item) => item?.id === Number(selectedProperty)
    )[0] || null;

  const filters: DesktopFiltersProps = {
    filterData:
      userRole === "ciso"
        ? [
            {
              type: "selector",
              value: selectedProperty,
              setValue: setSelectedProperty,
              disabled: isLoading,
              tooltip: "",
              function: () => {},
              array: propertiesArray,
              size: "small",
              icon: "",
              label: labels.access_points,
              multiDateValue: null,
              setMultiDateValue: () => {},
              filterWidth: 350,
            },
            {
              type: "search",
              value: searchFilter,
              setValue: setSearchFilter,
              disabled: isLoading,
              tooltip: "",
              function: () => {},
              array: [],
              size: "small",
              icon: "",
              label: "",
              multiDateValue: null,
              setMultiDateValue: () => {},
              filterWidth: 350,
            },
            {
              type: "function",
              value: "",
              setValue: () => {},
              disabled: isLoading,
              tooltip: labels.download_content,
              function: () => {},
              array: [],
              size: "small",
              icon: "fa-solid:file-download",
              label: "",
              multiDateValue: null,
              setMultiDateValue: () => {},
              downloadable: true,
              downloadData: dataTable,
              downloadHeader: headers,
              downloadFilename: `${labels.access_points}-${selectedPropertyName?.attributes?.name} ${currentDate}`,
            },
          ]
        : [
            {
              type: "search",
              value: searchFilter,
              setValue: setSearchFilter,
              disabled: isLoading,
              tooltip: "",
              function: () => {},
              array: [],
              size: "small",
              icon: "",
              label: "",
              multiDateValue: null,
              setMultiDateValue: () => {},
              filterWidth: 350,
            },
            {
              type: "function",
              value: "",
              setValue: () => {},
              disabled: isLoading,
              tooltip: labels.download_content,
              function: () => {},
              array: [],
              size: "small",
              icon: "fa-solid:file-download",
              label: "",
              multiDateValue: null,
              setMultiDateValue: () => {},
              downloadable: true,
              downloadData: dataTable,
              downloadHeader: headers,
              downloadFilename: `${labels.access_points}-${selectedPropertyName?.attributes?.name} ${currentDate}`,
            },
          ],
  };

  const onMapLoad = React.useCallback((map: any) => {
    setMap(map);
  }, []);

  React.useEffect(() => {
    if (map && coordinates.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      coordinates.forEach((coordinate: any) => {
        bounds.extend(
          new window.google.maps.LatLng(coordinate.lat, coordinate.lng)
        );
      });
      map.fitBounds(bounds);
    }
  }, [map, coordinates]);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  React.useEffect(() => {
    getAccessPoints(debouncedSearchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    labels,
    fixedPage,
    rowsPerPage,
    userRole,
    companyId,
    selectedProperty,
    debouncedSearchText,
  ]);

  const getAccessPoints = async (debouncedSearchText: string) => {
    try {
      setIsLoading(true);
      let response;
      if (userRole === "ciso") {
        if (companyId && selectedProperty) {
          response = await api.accessPointsCISORequest(
            companyId,
            Number(selectedProperty),
            page,
            rowsPerPage,
            debouncedSearchText
          );
        }
      } else {
        response = await api.accessPointsRequest(
          page,
          rowsPerPage,
          debouncedSearchText
        );
      }
      if (response) {
        // const headers = [
        //   { label: "ID", key: "ID" },
        //   { label: labels.name, key: labels.name },
        //   { label: labels.location, key: labels.location },
        //   { label: labels.address, key: labels.address },
        //   { label: labels.gates, key: labels.gates },
        //   { label: labels.time_zone, key: labels.time_zone },
        //   { label: labels.latitude, key: labels.latitude },
        //   { label: labels.longitude, key: labels.longitude },
        // ];
        const dataForDownloadableTable = response.data.map((item) => {
          return {
            ID: `${item?.id}`,
            [labels.name]: `${item?.attributes?.name}`,
            [labels.location]: `${item?.attributes?.vanue_name}`,
            [labels.address]: `${item?.attributes?.address}`,
            [labels.gates]: `${item?.attributes?.gates?.data.length}`,
            [labels.time_zone]: `${item?.attributes?.time_zone}`,
            [labels.latitude]: `${item?.attributes?.latitude}`,
            [labels.longitude]: `${item?.attributes?.longitude}`,
          };
        });
        setDataTable(dataForDownloadableTable);
        const totalRows = response?.meta?.pagination?.total;
        setTotalInvitations(totalRows);
        const getCoordinates = response?.data.map((element) => ({
          lat: element?.attributes?.latitude,
          lng: element?.attributes?.longitude,
        }));
        setCoordinates(getCoordinates);
        const dataForTable: TableData = {
          header: [
            {
              label: labels.name,
              isIcon: false,
              tooltipLabel: "",
            },
            {
              label: labels.location,
              isIcon: false,
              tooltipLabel: "",
            },
            {
              label: labels.gates,
              isIcon: false,
              tooltipLabel: "",
            },
          ],
          body: response.data.map((item) => {
            return {
              row: item.id,
              cells: [
                {
                  label: [
                    {
                      mainLabel: item?.attributes?.name,
                      tooltipLabel: "",
                    },
                  ],
                  isIcon: false,
                  display: "flex-dir-row",
                },
                {
                  label: [
                    {
                      mainLabel: item?.attributes?.vanue_name,
                      tooltipLabel: "",
                    },
                  ],
                  isIcon: false,
                  display: "flex-dir-row",
                },
                {
                  label: [
                    {
                      mainLabel: `${item?.attributes?.gates?.data?.length}`,
                      tooltipLabel: "",
                    },
                  ],
                  isIcon: false,
                  display: "flex-dir-row",
                },
              ],
            };
          }),
        };
        setTableDataToDisplay(dataForTable);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const getProperties = async () => {
    if (companyId) {
      try {
        setIsLoading(true);
        const propertyResponse = await api.propertiesByCompanyRequest(
          companyId
        );
        if (propertyResponse) {
          setPropertiesFullData(propertyResponse);
          setProperties(propertyResponse);
          setSelectedProperty(`${propertyResponse?.data[0]?.id}`);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="w-100">
      <DesktopHeader title={labels.access_points} />
      <DesktopBody hasFilters={true} filterData={filters.filterData}>
        <div className="w-100 h-100 px-8 pb-8 border-box">
          {isLoading ? (
            <div className="w-100 h-100 border-box flex align-items-center justify-content-center">
              <CircularProgress />
            </div>
          ) : (
            <div className="w-100 h-100 border-box flex gap-8">
              <div className="w-50 h-100 flex">
                <div className="w-100 h-100 pb-8 border-box">
                  {isLoading ? (
                    <div className="w-100 h-100 border-box flex align-items-center justify-content-center">
                      <CircularProgress />
                    </div>
                  ) : (
                    <TableComponent
                      selectableRow={true}
                      data={tableDataToDisplay}
                      onPressRow={(id: number) => {
                        setSelectedAccessPointId(id);
                        setShowAccessPointDetails(true);
                      }}
                      page={page}
                      setPage={setPage}
                      rowsPerPage={rowsPerPage}
                      setRowsPerPage={setRowsPerPage}
                      total={totalInvitations}
                      tableHeight={"calc(100vh - 188px)"}
                    />
                  )}
                </div>
              </div>
              <div className="w-50 h-100flex  map-container">
                {isLoaded && (
                  <GoogleMap
                    mapContainerStyle={mapStyles}
                    onLoad={onMapLoad}
                    onUnmount={onUnmount}
                  >
                    {coordinates.map((coordinate: any, index: any) => (
                      <Marker key={index} position={coordinate} />
                    ))}
                  </GoogleMap>
                )}
              </div>
            </div>
          )}
        </div>
      </DesktopBody>
      <DesktopDropView
        showView={showAccessPointDetails}
        setShowView={setShowAccessPointDetails}
        content={
          <div className="flex h-100 border-box">
            <div className="w-100 h-100 border-box p-8 scroll">
              <DesktopAccessPointDetails
                accessPointId={selectedAccessPointId}
                setShowView={setShowAccessPointDetails}
                extraFunc={() => getAccessPoints(debouncedSearchText)}
              />
            </div>
          </div>
        }
        extraFunc={false}
        title={selectedAccessPointName}
      />
    </div>
  );
};

export default AccessPoints;
