import React from "react";
import DesktopHeader from "../../components/DesktopHeader";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import DesktopBody from "../../components/DesktopBody";
import TableComponent from "../../components/TableComponent";
import { TableData } from "../../components/TableComponent/TableComponent";
import api from "../../api";
import dayjs, { Dayjs } from "dayjs";
import { CircularProgress } from "@mui/material";
import { DesktopFiltersProps } from "../../components/DesktopFilters/DesktopFilters";
import { selectUser } from "../../slices/user";
import DesktopDropView from "../../components/DesktopDropView";
import NewInvitationForm from "../../components/NewInvitationForm";
import DesktopInvitationDetails from "../../components/DesktopInvitationDetails";

const AccessKeys = () => {
  const labels = useAppSelector(selectLabels);
  const user = useAppSelector(selectUser);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [tableDataToDisplay, setTableDataToDisplay] =
    React.useState<TableData | null>(null);
  const [searchFilter, setSearchFilter] = React.useState<string>("");
  const [selectedAccessPoint, setSelectedAccessPoint] =
    React.useState<string>("0");
  const [selectedState, setSelectedState] = React.useState<
    "" | "entry" | "exit" | "try" | "null" | "deleted"
  >("");
  const [selectedDateFilter, setSelectedDateFilter] =
    React.useState<string>("all_dates");
  const [showAccessKeyDetails, setShowAccessKeyDetails] =
    React.useState<boolean>(false);
  const [newInvitation, setNewInvitation] = React.useState<boolean>(false);
  const [dateFilter, setDateFilter] = React.useState<Dayjs | null>(null);
  const [selectedInvitationId, setSelectedInvitationId] = React.useState<
    number | null
  >(null);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalInvitations, setTotalInvitations] = React.useState<number>(0);

  const fixedPage = React.useMemo(() => {
    return page + 1;
  }, [page]);

  const isoDate = dateFilter ? dayjs(dateFilter).toISOString() : "";
  console.log("isoDate", isoDate);

  const accessPointsArray = user?.access_points
    ? user.access_points.map((item) => {
        return {
          label: item?.name,
          value: `${item.id}`,
        };
      })
    : [];

  const datesFilterArray = [
    {
      label: labels.all_dates,
      value: "all_dates",
    },
    {
      label: labels.today,
      value: "today",
    },
    {
      label: labels.last_week,
      value: "last_week",
    },
    {
      label: labels.last_month,
      value: "last_month",
    },
    {
      label: labels.calendar,
      value: "calendar",
    },
  ];

  accessPointsArray.unshift({
    label: labels.all_access_points,
    value: "0",
  });

  const filters: DesktopFiltersProps = {
    filterData: [
      {
        type: "multidate",
        value: selectedDateFilter,
        setValue: setSelectedDateFilter,
        disabled: isLoading,
        tooltip: "",
        function: () => {},
        array: datesFilterArray,
        size: "small",
        icon: "",
        label: labels.access_points,
        multiDateValue: dateFilter,
        setMultiDateValue: setDateFilter,
      },
      {
        type: "selector",
        value: selectedAccessPoint,
        setValue: setSelectedAccessPoint,
        disabled: isLoading,
        tooltip: "",
        function: () => {},
        array: accessPointsArray,
        size: "small",
        icon: "",
        label: labels.access_points,
        multiDateValue: null,
        setMultiDateValue: () => {},
      },
      {
        type: "icon",
        value: selectedState,
        setValue: setSelectedState,
        disabled: isLoading,
        tooltip: labels.entries,
        function: () => {},
        array: [],
        size: "small",
        icon: "entypo:login",
        label: "entry",
        multiDateValue: null,
        setMultiDateValue: () => {},
      },
      {
        type: "icon",
        value: selectedState,
        setValue: setSelectedState,
        disabled: isLoading,
        tooltip: labels.exits,
        function: () => {},
        array: [],
        size: "small",
        icon: "entypo:log-out",
        label: "exit",
        multiDateValue: null,
        setMultiDateValue: () => {},
      },
      {
        type: "icon",
        value: selectedState,
        setValue: setSelectedState,
        disabled: isLoading,
        tooltip: labels.not_authorized_ak,
        function: () => {},
        array: [],
        size: "small",
        icon: "entypo:block",
        label: "try",
        multiDateValue: null,
        setMultiDateValue: () => {},
      },
      {
        type: "icon",
        value: selectedState,
        setValue: setSelectedState,
        disabled: isLoading,
        tooltip: labels.unused,
        function: () => {},
        array: [],
        size: "small",
        icon: "fa-solid:qrcode",
        label: "null",
        multiDateValue: null,
        setMultiDateValue: () => {},
      },
      {
        type: "icon",
        value: selectedState,
        setValue: setSelectedState,
        disabled: isLoading,
        tooltip: labels.deleted,
        function: () => {},
        array: [],
        size: "small",
        icon: "f7:trash-fill",
        label: "deleted",
        multiDateValue: null,
        setMultiDateValue: () => {},
      },
      {
        type: "search",
        value: searchFilter,
        setValue: setSearchFilter,
        disabled: isLoading,
        tooltip: "",
        function: () => {},
        array: [],
        size: "small",
        icon: "",
        label: "",
        multiDateValue: null,
        setMultiDateValue: () => {},
      },
      {
        type: "function",
        value: "",
        setValue: () => {},
        disabled: isLoading,
        tooltip: labels.download_content,
        function: () => {},
        array: [],
        size: "small",
        icon: "fa-solid:file-download",
        label: "",
        multiDateValue: null,
        setMultiDateValue: () => {},
      },
      {
        type: "function",
        value: "",
        setValue: () => {},
        disabled: isLoading,
        tooltip: "",
        function: () => setNewInvitation(true),
        array: [],
        size: "small",
        icon: "fa6-solid:circle-plus",
        label: "",
        multiDateValue: null,
        setMultiDateValue: () => {},
      },
    ],
  };

  React.useEffect(() => {
    getAccessKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels, fixedPage, rowsPerPage]);

  const getAccessKeys = async () => {
    try {
      setIsLoading(true);
      const response = await api.accessKeysRequest(fixedPage, rowsPerPage);
      if (response) {
        const totalRows = response?.meta?.pagination?.total;
        setTotalInvitations(totalRows);
        const dataForTable: TableData = {
          header: [
            {
              label: labels.visitor,
              isIcon: false,
              tooltipLabel: "",
            },
            {
              label: labels.type,
              isIcon: false,
              tooltipLabel: "",
            },
            {
              label: labels.user,
              isIcon: false,
              tooltipLabel: "",
            },
            {
              label: labels.access_points,
              isIcon: false,
              tooltipLabel: "",
            },
            {
              label: labels.valid_from,
              isIcon: false,
              tooltipLabel: "",
            },
            {
              label: labels.valid_until,
              isIcon: false,
              tooltipLabel: "",
            },
            {
              label: labels.state,
              isIcon: false,
              tooltipLabel: "",
            },
          ],
          body: response.data.map((item) => {
            return {
              row: item.id,
              cells: [
                {
                  label: [
                    {
                      mainLabel: item?.attributes?.verified_visitor
                        ? item?.attributes?.verified_visitor?.data?.attributes
                            ?.username
                        : "",
                      tooltipLabel: "",
                    },
                  ],
                  isIcon: false,
                  display: "flex-dir-row",
                },
                {
                  label: [
                    {
                      mainLabel:
                        item?.attributes?.verified_visitor?.data !== null
                          ? labels.recurrent
                          : "",
                      tooltipLabel: "",
                    },
                  ],
                  isIcon: false,
                  display: "flex-dir-row",
                },
                {
                  label: [
                    {
                      mainLabel:
                        item?.attributes?.created?.data?.attributes?.username,
                      tooltipLabel: "",
                    },
                  ],
                  isIcon: false,
                  display: "flex-dir-row",
                },
                {
                  label: item?.attributes?.access_points?.data.map(
                    (accessPoint) => {
                      return {
                        mainLabel: accessPoint?.attributes?.name,
                        tooltipLabel: "",
                      };
                    }
                  ),
                  isIcon: false,
                  display: "flex-dir-col",
                },
                {
                  label: dayjs(item?.attributes?.valid_from).isValid()
                    ? [
                        {
                          mainLabel:
                            dayjs(item?.attributes?.valid_from).format("DD") +
                            "/" +
                            dayjs(item?.attributes?.valid_from)
                              .format("MMMM")
                              .charAt(0)
                              .toUpperCase() +
                            dayjs(item?.attributes?.valid_from)
                              .format("MMMM")
                              .slice(1),
                          tooltipLabel: "",
                        },
                        {
                          mainLabel: dayjs(item?.attributes?.valid_from).format(
                            "h:mma"
                          ),
                          tooltipLabel: "",
                        },
                      ]
                    : [
                        {
                          mainLabel: labels.invalid_date,
                          tooltipLabel: "",
                        },
                      ],
                  isIcon: false,
                  display: "flex-dir-col",
                },
                {
                  label: dayjs(item?.attributes?.valid_until).isValid()
                    ? [
                        {
                          mainLabel:
                            dayjs(item?.attributes?.valid_until).format("DD") +
                            "/" +
                            dayjs(item?.attributes?.valid_until)
                              .format("MMMM")
                              .charAt(0)
                              .toUpperCase() +
                            dayjs(item?.attributes?.valid_until)
                              .format("MMMM")
                              .slice(1),
                          tooltipLabel: "",
                        },
                        {
                          mainLabel: dayjs(
                            item?.attributes?.valid_until
                          ).format("h:mma"),
                          tooltipLabel: "",
                        },
                      ]
                    : [
                        {
                          mainLabel: labels.invalid_date,
                          tooltipLabel: "",
                        },
                      ],
                  isIcon: false,
                  display: "flex-dir-col",
                },
                {
                  label: [
                    {
                      mainLabel:
                        item.attributes.last_transaction_type === null
                          ? "fa-solid:qrcode"
                          : "",
                      tooltipLabel: labels.not_used,
                    },
                  ],
                  isIcon: true,
                  display: "flex-dir-row",
                },
              ],
            };
          }),
        };
        setTableDataToDisplay(dataForTable);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-100">
      <DesktopHeader title={labels.access_keys} />
      <DesktopBody hasFilters={true} filterData={filters.filterData}>
        <div className="w-100 h-100 px-8 pb-8 border-box">
          {isLoading ? (
            <div className="w-100 h-100 border-box flex align-items-center justify-content-center">
              <CircularProgress />
            </div>
          ) : (
            <TableComponent
              selectableRow={true}
              data={tableDataToDisplay}
              onPressRow={(id: number) => {
                setSelectedInvitationId(id);
                setShowAccessKeyDetails(true);
              }}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              total={totalInvitations}
              tableHeight={"calc(100vh - 188px)"}
            />
          )}
        </div>
      </DesktopBody>
      <DesktopDropView
        showView={showAccessKeyDetails}
        setShowView={setShowAccessKeyDetails}
        content={
          <div className="flex h-100 border-box" style={{ width: 500 }}>
            <div className="w-100 h-100 border-box p-8 scroll">
              <DesktopInvitationDetails invitationId={selectedInvitationId} />
            </div>
          </div>
        }
        extraFunc={false}
        title={labels.invitation_details}
      />
      <DesktopDropView
        showView={newInvitation}
        setShowView={setNewInvitation}
        content={
          <div className="flex h-100 border-box">
            <div className="h-100 border-box p-8 scroll">
              <NewInvitationForm />
            </div>
          </div>
        }
        extraFunc={false}
        title={labels.new_invitation}
      />
    </div>
  );
};

export default AccessKeys;
