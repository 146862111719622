import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import React from "react";
import Logo from "../../components/Logo";
import "./logo.css";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingModal from "../../components/Modals/LoadingModal";
import api, { LoginPayload } from "../../api";
import { selectUserRole, setUser } from "../../slices/user";
import { setToken } from "../../slices/token";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const labels = useAppSelector(selectLabels);
  const userRole = useAppSelector(selectUserRole);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [rememberPassword, setRememberPassword] =
    React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    const savedEmail = localStorage.getItem("user_email") || null;
    const savedPassword = localStorage.getItem("password") || null;
    if (savedEmail && savedPassword) {
      setPassword(savedPassword);
      setEmail(savedEmail);
    }
  }, []);

  const handleChangeRememberPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRememberPassword(event.target.checked);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSubmit = async () => {
    if (email && password) {
      const adjustedEmail = email.trim().toLowerCase();
      const payload: LoginPayload = {
        identifier: adjustedEmail,
        password,
      };
      try {
        setIsLoading(true);
        if (rememberPassword) {
          localStorage.setItem("user_email", adjustedEmail);
          localStorage.setItem("password", password);
        }
        const response = await api.loginRequest(payload);
        if (response) {
          dispatch(setToken(response?.jwt));
          getUserInfo(response?.user?.id, response?.jwt);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getUserInfo = async (userId: number, token: string) => {
    if (userId && token) {
      try {
        setIsLoading(true);
        const response = await api.userByIdRequest(userId, token);
        if (response) {
          dispatch(setUser(response));
          if (response?.role?.id === 7) {
            navigate("/properties");
          } else {
            navigate("/access-keys");
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Container maxWidth="xs" className="mt-10 mb-10 general-background">
      <LoadingModal open={isLoading} setOpen={setIsLoading} />
      <Paper
        variant="outlined"
        className="p-10 vh-90 flex flex-dir-col justify-content-center align-items-center border-box scroll"
      >
        <Stack
          className="w-100"
          justifyContent={"center"}
          alignItems={"center"}
          spacing={3}
        >
          <Logo type={"passTrackLogo"} alt="passtrack" className="logo mt-12" />
          <p className="main-title mt-12">{labels.log_in}</p>
          <div className="w-100 mt-12">
            <p className="body-text-sm mb-3">{labels.email} *</p>
            <TextField
              fullWidth
              id="email"
              placeholder={"email@example.com"}
              variant="outlined"
              value={email}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value);
              }}
            />
          </div>
          <div className="w-100">
            <p className="body-text-sm mb-3">{labels.password} *</p>
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                id="password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(event.target.value);
                }}
                placeholder="Password123"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          <Stack
            className="w-100"
            direction={"row"}
            justifyContent={"flex-start"}
          >
            <FormControlLabel
              label={labels.remember_password}
              control={
                <Checkbox
                  checked={rememberPassword}
                  onChange={handleChangeRememberPassword}
                />
              }
            />
          </Stack>
          <Button
            fullWidth
            disableElevation
            size="large"
            variant="contained"
            disabled={!email || !password}
            onClick={onSubmit}
          >
            {labels.log_in}
          </Button>
          <p className="link-text mt-12">{labels.forgot_your_password}</p>
        </Stack>
      </Paper>
    </Container>
  );
};

export default Login;
