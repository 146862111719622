import ine_image from "../assets/ine_mock.jpeg";
import { AccessArray } from "../api/models/access-model";

export const accessMockData: AccessArray = {
  data: [
    {
      id: 6,
      attributes: {
        uuid: "6",
        property: {
          data: {
            id: 1,
            attributes: {
              uuid: "c9b13c29-4726-48ed-aa48-5c648f9e56cf",
              logo_url: "",
              settings: "",
              passback_activated_by_default: true,
              createdAt: "2024-03-15T14:25:11.447Z",
              updatedAt: "2024-06-07T08:12:14.404Z",
              locale: "en",
              type: "Residential",
              address: "",
              block_secondary_user: false,
              localizations: "",
            },
          },
        },
        company: {
          data: {
            id: 1,
            attributes: {
              name: "PassTrack",
              logo_url: "",
              createdAt: "2024-03-15T14:23:25.779Z",
              updatedAt: "2024-03-15T14:23:25.779Z",
              locale: "en",
              tax_id: "",
              legal_name: "",
              localizations: "",
            },
          },
        },
        access_key_id: null,
        register_id: 2,
        entrance_transaction: {
          data: {
            id: 1,
            attributes: {
              uuid: "",
              datetime: "2024-06-14T14:25:00.000Z",
              access_type: "access",
              access_key_type: null, // 1: visit, 2: temporary, 3: event, 4: provider, 5: dinamic-key
              access_key_id: "",
              gate: {
                data: {
                  id: 1,
                  attributes: {
                    name: "Acceso vehicular 1 - Visitantes",
                    type: "entrance",
                    createdAt: "2024-04-26T02:12:18.098Z",
                    updatedAt: "2024-04-26T02:12:18.098Z",
                    locale: "en",
                  },
                },
              },
              access_point: {
                data: {
                  id: 1,
                  attributes: {
                    time_zone: "America/Mexico_City",
                    longitude: null,
                    latitude: null,
                    address:
                      "Carretera Guadalajara - El Salto vía El Verde 2004, Col, Jardines del Country, 44210 El Salto, Jal.",
                    createdAt: "2024-04-19T07:24:03.102Z",
                    updatedAt: "2024-05-03T14:17:41.621Z",
                    locale: "en",
                    name: "Acceso Vehicular Principal",
                    vanue_name: "Vesta Park Guadalajara 1",
                    localizations: "",
                  },
                },
              },
              createdAt: "2024-01-24T13:32:00.000Z",
              updatedAt: "",
              locale: "",
              localizations: "",
              register_by: "Mario Lopez",
              photos: [ine_image],
            },
          },
        },
        createdAt: "",
        updatedAt: "",
        locale: "",
        localizations: "",
        visitor: "Daniel Almarás",
        user: "Miguel Garduño",
        unit: "Departamento 302",
        authorized: true,
      },
    },
    {
      id: 5,
      attributes: {
        uuid: "5",
        property: {
          data: {
            id: 1,
            attributes: {
              uuid: "c9b13c29-4726-48ed-aa48-5c648f9e56cf",
              logo_url: "",
              settings: "",
              passback_activated_by_default: true,
              createdAt: "2024-03-15T14:25:11.447Z",
              updatedAt: "2024-06-07T08:12:14.404Z",
              locale: "en",
              type: "Residential",
              address: "",
              block_secondary_user: false,
              localizations: "",
            },
          },
        },
        company: {
          data: {
            id: 1,
            attributes: {
              name: "PassTrack",
              logo_url: "",
              createdAt: "2024-03-15T14:23:25.779Z",
              updatedAt: "2024-03-15T14:23:25.779Z",
              locale: "en",
              tax_id: "",
              legal_name: "",
              localizations: "",
            },
          },
        },
        access_key_id: 5,
        register_id: null,
        entrance_transaction: {
          data: {
            id: 1,
            attributes: {
              uuid: "",
              datetime: "2024-06-16T14:14:00.000Z",
              access_type: "access",
              access_key_type: 1, // 1: visit, 2: temporary, 3: event, 4: provider, 5: dinamic-key
              access_key_id: "5",
              gate: {
                data: {
                  id: 1,
                  attributes: {
                    name: "Acceso vehicular 1 - Visitantes",
                    type: "entrance",
                    createdAt: "2024-04-26T02:12:18.098Z",
                    updatedAt: "2024-04-26T02:12:18.098Z",
                    locale: "en",
                  },
                },
              },
              access_point: {
                data: {
                  id: 1,
                  attributes: {
                    time_zone: "America/Mexico_City",
                    longitude: null,
                    latitude: null,
                    address:
                      "Carretera Guadalajara - El Salto vía El Verde 2004, Col, Jardines del Country, 44210 El Salto, Jal.",
                    createdAt: "2024-04-19T07:24:03.102Z",
                    updatedAt: "2024-05-03T14:17:41.621Z",
                    locale: "en",
                    name: "Acceso Vehicular Principal",
                    vanue_name: "Vesta Park Guadalajara 1",
                    localizations: "",
                  },
                },
              },
              createdAt: "2024-01-24T13:32:00.000Z",
              updatedAt: "",
              locale: "",
              localizations: "",
              register_by: "Mario Lopez",
              photos: [ine_image],
            },
          },
        },
        createdAt: "",
        updatedAt: "",
        locale: "",
        localizations: "",
        visitor: "Esperanza Cárdenas",
        user: "Uriel Anaya",
        unit: "Departamento 304",
        authorized: true,
      },
    },
    {
      id: 4,
      attributes: {
        uuid: "4",
        property: {
          data: {
            id: 1,
            attributes: {
              uuid: "c9b13c29-4726-48ed-aa48-5c648f9e56cf",
              logo_url: "",
              settings: "",
              passback_activated_by_default: true,
              createdAt: "2024-03-15T14:25:11.447Z",
              updatedAt: "2024-06-07T08:12:14.404Z",
              locale: "en",
              type: "Residential",
              address: "",
              block_secondary_user: false,
              localizations: "",
            },
          },
        },
        company: {
          data: {
            id: 1,
            attributes: {
              name: "PassTrack",
              logo_url: "",
              createdAt: "2024-03-15T14:23:25.779Z",
              updatedAt: "2024-03-15T14:23:25.779Z",
              locale: "en",
              tax_id: "",
              legal_name: "",
              localizations: "",
            },
          },
        },
        access_key_id: null,
        register_id: 1,
        entrance_transaction: {
          data: {
            id: 1,
            attributes: {
              uuid: "",
              datetime: "2024-06-13T19:02:00.000Z",
              access_type: "access",
              access_key_type: null, // 1: visit, 2: temporary, 3: event, 4: provider, 5: dinamic-key
              access_key_id: "",
              gate: {
                data: {
                  id: 1,
                  attributes: {
                    name: "Demo PassTrack",
                    type: "entrance",
                    createdAt: "2024-04-26T02:12:18.098Z",
                    updatedAt: "2024-04-26T02:12:18.098Z",
                    locale: "en",
                  },
                },
              },
              access_point: {
                data: {
                  id: 1,
                  attributes: {
                    time_zone: "America/Mexico_City",
                    longitude: -99.2039381,
                    latitude: 19.4,
                    address: "Av. Constituyentes 455, CDMX",
                    createdAt: "2024-04-19T07:24:03.102Z",
                    updatedAt: "2024-05-03T14:17:41.621Z",
                    locale: "en",
                    name: "Acceso EP Summit",
                    vanue_name: "CENTRO",
                    localizations: "",
                  },
                },
              },
              createdAt: "2024-01-24T13:32:00.000Z",
              updatedAt: "",
              locale: "",
              localizations: "",
              register_by: "Juan Perez",
              photos: [ine_image],
            },
          },
        },
        exit_transaction: {
          data: {
            id: 2,
            attributes: {
              uuid: "",
              datetime: "2024-06-13T21:28:00.000Z",
              access_type: "exit",
              access_key_type: 1, // 1: visit, 2: temporary, 3: event, 4: provider, 5: dinamic-key
              access_key_id: "1",
              gate: {
                data: {
                  id: 2,
                  attributes: {
                    name: "Demo PassTrack",
                    type: "exit",
                    createdAt: "2024-04-26T02:12:18.098Z",
                    updatedAt: "2024-04-26T02:12:18.098Z",
                    locale: "en",
                  },
                },
              },
              access_point: {
                data: {
                  id: 1,
                  attributes: {
                    time_zone: "America/Mexico_City",
                    longitude: -99.2039381,
                    latitude: 19.4,
                    address: "Av. Constituyentes 455, CDMX",
                    createdAt: "2024-04-19T07:24:03.102Z",
                    updatedAt: "2024-05-03T14:17:41.621Z",
                    locale: "en",
                    name: "Acceso EP Summit",
                    vanue_name: "CENTRO",
                    localizations: "",
                  },
                },
              },
              createdAt: "2024-01-24T16:07:00.000Z",
              updatedAt: "",
              locale: "",
              localizations: "",
              register_by: "Juan Perez",
            },
          },
        },
        createdAt: "",
        updatedAt: "",
        locale: "",
        localizations: "",
        visitor: "Eduardo Reyes",
        user: "Lourdes Miranda",
        unit: "Departamento 205",
        authorized: true,
      },
    },
    {
      id: 1,
      attributes: {
        uuid: "1",
        property: {
          data: {
            id: 1,
            attributes: {
              uuid: "c9b13c29-4726-48ed-aa48-5c648f9e56cf",
              logo_url: "",
              settings: "",
              passback_activated_by_default: true,
              createdAt: "2024-03-15T14:25:11.447Z",
              updatedAt: "2024-06-07T08:12:14.404Z",
              locale: "en",
              type: "Residential",
              address: "",
              block_secondary_user: false,
              localizations: "",
            },
          },
        },
        company: {
          data: {
            id: 1,
            attributes: {
              name: "PassTrack",
              logo_url: "",
              createdAt: "2024-03-15T14:23:25.779Z",
              updatedAt: "2024-03-15T14:23:25.779Z",
              locale: "en",
              tax_id: "",
              legal_name: "",
              localizations: "",
            },
          },
        },
        access_key_id: 1,
        register_id: null,
        entrance_transaction: {
          data: {
            id: 1,
            attributes: {
              uuid: "",
              datetime: "2024-06-13T16:43:00.000Z",
              access_type: "access",
              access_key_type: 1, // 1: visit, 2: temporary, 3: event, 4: provider, 5: dinamic-key
              access_key_id: "1",
              gate: {
                data: {
                  id: 1,
                  attributes: {
                    name: "Demo PassTrack",
                    type: "entrance",
                    createdAt: "2024-04-26T02:12:18.098Z",
                    updatedAt: "2024-04-26T02:12:18.098Z",
                    locale: "en",
                  },
                },
              },
              access_point: {
                data: {
                  id: 1,
                  attributes: {
                    time_zone: "America/Mexico_City",
                    longitude: -99.2039381,
                    latitude: 19.4,
                    address: "Av. Constituyentes 455, CDMX",
                    createdAt: "2024-04-19T07:24:03.102Z",
                    updatedAt: "2024-05-03T14:17:41.621Z",
                    locale: "en",
                    name: "Acceso EP Summit",
                    vanue_name: "CENTRO",
                    localizations: "",
                  },
                },
              },
              createdAt: "2024-01-24T13:32:00.000Z",
              updatedAt: "",
              locale: "",
              localizations: "",
              register_by: "Juan Perez",
              photos: [ine_image],
            },
          },
        },
        exit_transaction: {
          data: {
            id: 2,
            attributes: {
              uuid: "",
              datetime: "2024-06-13T22:07:00.000Z",
              access_type: "exit",
              access_key_type: 1, // 1: visit, 2: temporary, 3: event, 4: provider, 5: dinamic-key
              access_key_id: "1",
              gate: {
                data: {
                  id: 2,
                  attributes: {
                    name: "Demo PassTrack",
                    type: "exit",
                    createdAt: "2024-04-26T02:12:18.098Z",
                    updatedAt: "2024-04-26T02:12:18.098Z",
                    locale: "en",
                  },
                },
              },
              access_point: {
                data: {
                  id: 1,
                  attributes: {
                    time_zone: "America/Mexico_City",
                    longitude: -99.2039381,
                    latitude: 19.4,
                    address: "Av. Constituyentes 455, CDMX",
                    createdAt: "2024-04-19T07:24:03.102Z",
                    updatedAt: "2024-05-03T14:17:41.621Z",
                    locale: "en",
                    name: "Acceso EP Summit",
                    vanue_name: "CENTRO",
                    localizations: "",
                  },
                },
              },
              createdAt: "2024-01-24T16:07:00.000Z",
              updatedAt: "",
              locale: "",
              localizations: "",
              register_by: "Juan Perez",
            },
          },
        },
        createdAt: "",
        updatedAt: "",
        locale: "",
        localizations: "",
        visitor: "Carlos Torres",
        user: "Jorge García",
        unit: "Departamento 201",
        authorized: true,
      },
    },
    {
      id: 3,
      attributes: {
        uuid: "3",
        property: {
          data: {
            id: 1,
            attributes: {
              uuid: "c9b13c29-4726-48ed-aa48-5c648f9e56cf",
              logo_url: "",
              settings: "",
              passback_activated_by_default: true,
              createdAt: "2024-03-15T14:25:11.447Z",
              updatedAt: "2024-06-07T08:12:14.404Z",
              locale: "en",
              type: "Residential",
              address: "",
              block_secondary_user: false,
              localizations: "",
            },
          },
        },
        company: {
          data: {
            id: 1,
            attributes: {
              name: "PassTrack",
              logo_url: "",
              createdAt: "2024-03-15T14:23:25.779Z",
              updatedAt: "2024-03-15T14:23:25.779Z",
              locale: "en",
              tax_id: "",
              legal_name: "",
              localizations: "",
            },
          },
        },
        access_key_id: 1,
        register_id: null,
        entrance_transaction: {
          data: {
            id: 1,
            attributes: {
              uuid: "",
              datetime: "2024-06-13T16:32:00.000Z",
              access_type: "access",
              access_key_type: 1, // 1: visit, 2: temporary, 3: event, 4: provider, 5: dinamic-key
              access_key_id: "1",
              gate: {
                data: {
                  id: 1,
                  attributes: {
                    name: "Acceso vehicular 1 - Visitantes",
                    type: "entrance",
                    createdAt: "2024-04-26T02:12:18.098Z",
                    updatedAt: "2024-04-26T02:12:18.098Z",
                    locale: "en",
                  },
                },
              },
              access_point: {
                data: {
                  id: 1,
                  attributes: {
                    time_zone: "America/Mexico_City",
                    longitude: null,
                    latitude: null,
                    address:
                      "Carretera Guadalajara - El Salto vía El Verde 2004, Col, Jardines del Country, 44210 El Salto, Jal.",
                    createdAt: "2024-04-19T07:24:03.102Z",
                    updatedAt: "2024-05-03T14:17:41.621Z",
                    locale: "en",
                    name: "Acceso Vehicular Principal",
                    vanue_name: "Vesta Park Guadalajara 1",
                    localizations: "",
                  },
                },
              },
              createdAt: "2024-01-24T13:32:00.000Z",
              updatedAt: "",
              locale: "",
              localizations: "",
              register_by: "Mario Lopez",
            },
          },
        },
        createdAt: "",
        updatedAt: "",
        locale: "",
        localizations: "",
        visitor: "Carlos Torres",
        user: "Jorge García",
        unit: "Departamento 201",
        authorized: false,
      },
    },
    {
      id: 2,
      attributes: {
        uuid: "2",
        property: {
          data: {
            id: 1,
            attributes: {
              uuid: "c9b13c29-4726-48ed-aa48-5c648f9e56cf",
              logo_url: "",
              settings: "",
              passback_activated_by_default: true,
              createdAt: "2024-03-15T14:25:11.447Z",
              updatedAt: "2024-06-07T08:12:14.404Z",
              locale: "en",
              type: "Residential",
              address: "",
              block_secondary_user: false,
              localizations: "",
            },
          },
        },
        company: {
          data: {
            id: 1,
            attributes: {
              name: "PassTrack",
              logo_url: "",
              createdAt: "2024-03-15T14:23:25.779Z",
              updatedAt: "2024-03-15T14:23:25.779Z",
              locale: "en",
              tax_id: "",
              legal_name: "",
              localizations: "",
            },
          },
        },
        access_key_id: 2,
        register_id: null,
        entrance_transaction: {
          data: {
            id: 3,
            attributes: {
              uuid: "",
              datetime: "2024-06-11T19:32:00.000Z",
              access_type: "access",
              access_key_type: 2, // 1: visit, 2: temporary, 3: event, 4: provider, 5: dinamic-key
              access_key_id: "2",
              gate: {
                data: {
                  id: 1,
                  attributes: {
                    name: "Acceso vehicular 1 - Visitantes",
                    type: "entrance",
                    createdAt: "2024-04-26T02:12:18.098Z",
                    updatedAt: "2024-04-26T02:12:18.098Z",
                    locale: "en",
                  },
                },
              },
              access_point: {
                data: {
                  id: 1,
                  attributes: {
                    time_zone: "America/Mexico_City",
                    longitude: null,
                    latitude: null,
                    address:
                      "Carretera Guadalajara - El Salto vía El Verde 2004, Col, Jardines del Country, 44210 El Salto, Jal.",
                    createdAt: "2024-04-19T07:24:03.102Z",
                    updatedAt: "2024-05-03T14:17:41.621Z",
                    locale: "en",
                    name: "Acceso Vehicular Principal",
                    vanue_name: "Vesta Park Guadalajara 1",
                    localizations: "",
                  },
                },
              },
              createdAt: "2024-01-24T13:32:00.000Z",
              updatedAt: "",
              locale: "",
              localizations: "",
              register_by: "Mario Lopez",
              photos: [ine_image],
            },
          },
        },
        exit_transaction: {
          data: {
            id: 4,
            attributes: {
              uuid: "",
              datetime: "2024-06-12T21:30:00.000Z",
              access_type: "exit",
              access_key_type: 2, // 1: visit, 2: temporary, 3: event, 4: provider, 5: dinamic-key
              access_key_id: "2",
              gate: {
                data: {
                  id: 2,
                  attributes: {
                    name: "Demo PassTrack",
                    type: "exit",
                    createdAt: "2024-04-26T02:12:18.098Z",
                    updatedAt: "2024-04-26T02:12:18.098Z",
                    locale: "en",
                  },
                },
              },
              access_point: {
                data: {
                  id: 1,
                  attributes: {
                    time_zone: "America/Mexico_City",
                    longitude: -99.2039381,
                    latitude: 19.4,
                    address: "Av. Constituyentes 455, CDMX",
                    createdAt: "2024-04-19T07:24:03.102Z",
                    updatedAt: "2024-05-03T14:17:41.621Z",
                    locale: "en",
                    name: "Acceso EP Summit",
                    vanue_name: "CENTRO",
                    localizations: "",
                  },
                },
              },
              createdAt: "2024-01-24T16:07:00.000Z",
              updatedAt: "",
              locale: "",
              localizations: "",
              register_by: "Juan Perez",
            },
          },
        },
        createdAt: "",
        updatedAt: "",
        locale: "",
        localizations: "",
        visitor: "Ilse Anaya",
        user: "Uriel Anaya",
        unit: "Departamento 304",
        authorized: true,
      },
    },
  ],
  meta: {
    pagination: {
      page: 1,
      pageSize: 2500,
      pageCount: 1,
      total: 5,
    },
  },
};
