import React from "react";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { AccessPointRelation } from "../../api/models/access-points";
import api, { AccessPointPayload } from "../../api";
import {
  Box,
  Button,
  CircularProgress,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { PhotoTypesArray } from "../../api/models/photo-types-model";
import IconifyIcon from "../IconifyIcon";

interface DesktopAccessPointDetailsProps {
  accessPointId: number | null;
  setShowView: (showView: boolean) => void;
  extraFunc: () => void;
}

const DesktopAccessPointDetails = ({
  accessPointId,
  setShowView,
  extraFunc,
}: DesktopAccessPointDetailsProps) => {
  const labels = useAppSelector(selectLabels);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [selectedAccessPoint, setSelectedAccessPoint] =
    React.useState<AccessPointRelation | null>(null);
  const [photoTypes, setPhotoTypes] = React.useState<PhotoTypesArray | null>(
    null
  );
  const [accessPointName, setAccessPointName] = React.useState<string>("");
  const [activePhotos, setActivePhotos] = React.useState<number[]>([]);

  const gates =
    selectedAccessPoint?.data?.attributes?.gates?.data.map((item) => {
      return {
        name: item?.attributes?.name,
        type: item?.attributes?.type,
      };
    }) || [];

  React.useEffect(() => {
    getSelectedAccessPoint();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessPointId]);

  const getSelectedAccessPoint = async () => {
    if (accessPointId) {
      try {
        setIsLoading(true);
        const response = await api.accessPointByIdRequest(accessPointId);
        if (response) {
          const currentPhotos =
            response?.data?.attributes?.photo_types?.data.map(
              (item) => item?.id
            ) || [];
          setSelectedAccessPoint(response);
          setAccessPointName(response?.data?.attributes?.name);
          setActivePhotos(currentPhotos);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  React.useEffect(() => {
    getPhotoTypes();
  }, []);

  const getPhotoTypes = async () => {
    try {
      setIsLoading(true);
      const response = await api.photoTypesRequest();
      if (response) {
        setPhotoTypes(response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const newActivePhotos = [...activePhotos];
    if (newActivePhotos.includes(id)) {
      const photoIndex = newActivePhotos.indexOf(id);
      newActivePhotos.splice(photoIndex, 1);
    } else {
      newActivePhotos.push(id);
    }
    setActivePhotos(newActivePhotos);
  };

  const onUpdateAccessPoint = async () => {
    if (accessPointId) {
      try {
        const payload: AccessPointPayload = {
          data: {
            name: accessPointName,
            photo_types: activePhotos,
          },
        };
        setIsLoading(true);
        const response = await api.updateAccessPoint(accessPointId, payload);
        if (response) {
          setShowView(false);
          extraFunc();
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="border-box flex flex-dir-col gap-4" style={{ width: 560 }}>
      {isLoading ? (
        <div
          className="w-100 border-box flex align-items-center justify-content-center"
          style={{ height: 200 }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <div
            className="w-100 border-box hidden flex flex-dir-col align-items-center gap-6 p-8"
            style={{
              backgroundColor: "white",
              border: "1px #e0e0e0 solid",
              borderRadius: 6,
            }}
          >
            <div className="w-100">
              <p className="t-nowrap body-text-lg font-w-500 title-color mb-4">
                {labels.details}
              </p>
            </div>
            <div className="w-100 border-box flex align-items-baseline gap-10">
              <div className="w-20">
                <p className="t-nowrap body-text-sm">{labels.location}:</p>
              </div>
              <div className="w-80">
                <p className="body-text-sm font-w-700 font-14">
                  {selectedAccessPoint?.data?.attributes?.vanue_name}
                </p>
              </div>
            </div>
            <div className="w-100 border-box flex align-items-baseline gap-10">
              <div className="w-20">
                <p className="t-nowrap body-text-sm">{labels.address}:</p>
              </div>
              <div className="w-80">
                <p className="body-text-sm font-w-700">
                  {selectedAccessPoint?.data?.attributes?.address}
                </p>
              </div>
            </div>
            <div className="w-100 border-box flex flex-dir-col gap-4">
              <div className="w-100">
                <p className="t-nowrap body-text-sm">{labels.gates}:</p>
              </div>
              <div className="w-100">
                <TableContainer
                  component={Box}
                  style={{ border: "1px #e0e0e0 solid" }}
                >
                  <Table
                    sx={{ minWidth: "100%" }}
                    size="small"
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          <div
                            className="body-text-xs"
                            style={{ lineHeight: 1.43 }}
                          >
                            {labels.name}
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          <div
                            className="body-text-xs"
                            style={{ lineHeight: 1.43 }}
                          >
                            {labels.type}
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          <div
                            className="body-text-xs"
                            style={{ lineHeight: 1.43 }}
                          >
                            {labels.label_printing}
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          <div
                            className="body-text-xs"
                            style={{ lineHeight: 1.43 }}
                          >
                            {labels.remote_pulse}
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {gates.map((row, index) => (
                        <TableRow
                          key={`${row.name}-${index}`}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center">
                            <div className="body-text-xs">{row.name}</div>
                          </TableCell>
                          <TableCell align="center">
                            <div className="body-text-xs">
                              {row.type === "exit" && labels.exit}
                              {row.type === "entrance" && labels.entrance}
                            </div>
                          </TableCell>
                          <TableCell align="center">{""}</TableCell>
                          <TableCell align="center">{""}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
          <div
            className="w-100 border-box hidden flex flex-dir-col align-items-center gap-6 p-8"
            style={{
              backgroundColor: "white",
              border: "1px #e0e0e0 solid",
              borderRadius: 6,
            }}
          >
            <div className="w-100">
              <p className="t-nowrap body-text-lg font-w-500 title-color mb-4">
                {labels.settings}
              </p>
            </div>
            <div className="w-100">
              <p className="t-nowrap body-text-xs mb-3">
                {labels.access_point_name}
              </p>
              <TextField
                fullWidth
                placeholder={labels.enter_your_name}
                value={accessPointName}
                size="small"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setAccessPointName(event.target.value);
                }}
              />
            </div>
            <div className="w-100">
              <p className="t-nowrap body-text-xs mb-3">
                {labels.required_photographs}
              </p>
              {photoTypes?.data.map((item, index) => (
                <div
                  key={`${item?.id}-${index}`}
                  className="w-100 border-box flex align-items-center justify-content-between px-7 mb-2"
                  style={{
                    borderRadius: 4,
                    border: "1px #e0e0e0 solid",
                    height: 40,
                  }}
                >
                  {item.attributes.name === "photo id" && (
                    <div className="flex align-items-center">
                      <div
                        className="flex align-items-center justify-content-center"
                        style={{ width: 40 }}
                      >
                        <IconifyIcon
                          icon={"fa-regular:id-card"}
                          className="font-22 subtitle-color"
                          onClick={() => {}}
                        />
                      </div>
                      <p className="t-nowrap body-text-md">{labels.id}</p>
                    </div>
                  )}
                  {item.attributes.name === "plates" && (
                    <div className="flex align-items-center">
                      <div
                        className="flex align-items-center justify-content-center"
                        style={{ width: 40 }}
                      >
                        <IconifyIcon
                          icon={"fa-solid:car-alt"}
                          className="font-22 subtitle-color"
                          onClick={() => {}}
                        />
                      </div>
                      <p className="t-nowrap body-text-md">{labels.plates}</p>
                    </div>
                  )}
                  {item.attributes.name === "visitor" && (
                    <div className="flex align-items-center">
                      <div
                        className="flex align-items-center justify-content-center"
                        style={{ width: 40 }}
                      >
                        <IconifyIcon
                          icon={"fa-solid:user"}
                          className="font-22 subtitle-color"
                          onClick={() => {}}
                        />
                      </div>
                      <p className="t-nowrap body-text-md">{labels.visitor}</p>
                    </div>
                  )}
                  {item.attributes.name === "equipment" && (
                    <div className="flex align-items-center">
                      <div
                        className="flex align-items-center justify-content-center"
                        style={{ width: 40 }}
                      >
                        <IconifyIcon
                          icon={"fa-solid:laptop"}
                          className="font-22 subtitle-color"
                          onClick={() => {}}
                        />
                      </div>
                      <p className="t-nowrap body-text-md">
                        {labels.equipment}
                      </p>
                    </div>
                  )}
                  <Switch
                    checked={activePhotos.includes(item?.id)}
                    onChange={(e) => handleChange(e, item?.id)}
                  />
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      <div
        className="w-100 border-box hidden flex flex-dir-col align-items-center gap-6 p-8"
        style={{
          backgroundColor: "white",
          border: "1px #e0e0e0 solid",
          borderRadius: 6,
        }}
      >
        <div className="w-100" style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            disableElevation
            onClick={onUpdateAccessPoint}
          >
            {labels.save_changes}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DesktopAccessPointDetails;
