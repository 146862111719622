import {
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
} from "@mui/material";
import React from "react";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import { Dayjs } from "dayjs";
import "./new-invitation-form.css";
import NewInvitationVisitorData from "../DesktopInvitationFormElements/NewInvitationVisitorData";
import NewInvitationValidity from "../DesktopInvitationFormElements/NewInvitationValidity";
import NewInvitationVisitDetails from "../DesktopInvitationFormElements/NewInvitationVisitDetails";

const NewInvitationForm = () => {
  const labels = useAppSelector(selectLabels);

  const [invitationType, setInvitationType] = React.useState<
    "visitor" | "provider"
  >("visitor");
  const [multipleAccess, setMultipleAccess] = React.useState<boolean>(false);
  const [countryCode, setCountryCode] = React.useState<"+52" | "+1">("+52");
  const [visitorName, setVisitorName] = React.useState<string>("");
  const [visitorPhone, setVisitorPhone] = React.useState<string>("");
  const [visitorEmail, setVisitorEmail] = React.useState<string>("");
  const [saveContact, setSaveContact] = React.useState<boolean>(false);
  const [date, setDate] = React.useState<Dayjs | null>(null);
  const [intervalNumber, setIntervalNumber] = React.useState<string>("1");
  const [intervalTime, setIntervalTime] = React.useState<string>("days");
  const [hour, setHour] = React.useState<string>("00");
  const [minutes, setMinutes] = React.useState<string>("00");
  const [hoursLater, setHoursLater] = React.useState<string>("1");
  const [weekDaysRestriction, setWeekDaysRestriction] = React.useState<
    string[]
  >([]);
  const [restrictions, setRestrictions] = React.useState<boolean>(false);
  const [selectedAccessPoints, setSelectedAccessPoints] = React.useState<
    string[]
  >([]);
  const [motive, setMotive] = React.useState<string>("");
  const [antipassback, setAntipassback] = React.useState<boolean>(false);

  const invitationTypeArray = [
    {
      label: labels.visitor,
      value: "visitor",
    },
    {
      label: labels.provider,
      value: "provider",
    },
  ];

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setState: (state: boolean) => void
  ) => {
    setState(event.target.checked);
  };

  const createInvitation = () => {
    alert("Invitación creada");
  };

  return (
    <div className="border-box flex flex-dir-col gap-4" style={{ width: 660 }}>
      <div
        className="w-100 border-box hidden flex align-items-center justify-content-between gap-10 p-8"
        style={{
          backgroundColor: "white",
          border: "1px #e0e0e0 solid",
          borderRadius: 6,
        }}
      >
        <div className="w-50">
          <FormControl fullWidth>
            <Select
              value={invitationType}
              onChange={(event: SelectChangeEvent) =>
                setInvitationType(event.target.value as "visitor" | "provider")
              }
              size="small"
            >
              {invitationTypeArray.map((item, index) => (
                <MenuItem key={`${index}-${item?.label}`} value={item?.value}>
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="w-50 flex align-items-center justify-content-end gap-2">
          <p className="t-nowrap body-text-md">{labels.multiple_access}</p>
          <Switch
            checked={multipleAccess}
            onChange={(e) => handleChange(e, setMultipleAccess)}
          />
        </div>
      </div>
      <NewInvitationVisitorData
        visitorName={visitorName}
        setVisitorName={setVisitorName}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        visitorPhone={visitorPhone}
        setVisitorPhone={setVisitorPhone}
        visitorEmail={visitorEmail}
        setVisitorEmail={setVisitorEmail}
        saveContact={saveContact}
        setSaveContact={setSaveContact}
      />
      <NewInvitationValidity
        multipleAccess={multipleAccess}
        restrictions={restrictions}
        setRestrictions={setRestrictions}
        date={date}
        setDate={setDate}
        hour={hour}
        setHour={setHour}
        minutes={minutes}
        setMinutes={setMinutes}
        hoursLater={hoursLater}
        setHoursLater={setHoursLater}
        intervalNumber={intervalNumber}
        setIntervalNumber={setIntervalNumber}
        intervalTime={intervalTime}
        setIntervalTime={setIntervalTime}
        weekDaysRestriction={weekDaysRestriction}
        setWeekDaysRestriction={setWeekDaysRestriction}
      />
      <NewInvitationVisitDetails
        motive={motive}
        setMotive={setMotive}
        multipleAccess={multipleAccess}
        antipassback={antipassback}
        setAntipassback={setAntipassback}
        selectedAccessPoints={selectedAccessPoints}
        setSelectedAccessPoints={setSelectedAccessPoints}
      />
      <div
        className="w-100 border-box hidden flex flex-dir-col align-items-center gap-6 p-8"
        style={{
          backgroundColor: "white",
          border: "1px #e0e0e0 solid",
          borderRadius: 6,
        }}
      >
        <div className="w-100" style={{ textAlign: "center" }}>
          <p className="t-nowrap body-text-sm">* {labels.mandatory_fields}</p>
        </div>
        <div className="w-100" style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            disableElevation
            onClick={createInvitation}
          >
            {labels.create_and_send_inv}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewInvitationForm;
