import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
} from "@mui/material";
import React from "react";
import DatePickerCustom from "../../FormElements/DatePickerCustom";
import { useAppSelector } from "../../../app/hooks";
import { selectLabels } from "../../../slices/language";
import { Dayjs } from "dayjs";
import {
  daysArray,
  hourArray,
  hoursLaterArray,
  minutesArray,
  monthsArray,
  weeksArray,
} from "../../../utils/validity";

interface NewInvitationValidityProps {
  multipleAccess: boolean;
  restrictions: boolean;
  setRestrictions: (restrictions: boolean) => void;
  date: Dayjs | null;
  setDate: (date: Dayjs | null) => void;
  hour: string;
  setHour: (hour: string) => void;
  minutes: string;
  setMinutes: (minutes: string) => void;
  hoursLater: string;
  setHoursLater: (hoursLater: string) => void;
  intervalNumber: string;
  setIntervalNumber: (intervalNumber: string) => void;
  intervalTime: string;
  setIntervalTime: (intervalTime: string) => void;
  weekDaysRestriction: string[];
  setWeekDaysRestriction: (weekDaysRestriction: string[]) => void;
}

const NewInvitationValidity = ({
  multipleAccess,
  restrictions,
  setRestrictions,
  date,
  setDate,
  hour,
  setHour,
  minutes,
  setMinutes,
  hoursLater,
  setHoursLater,
  intervalNumber,
  setIntervalNumber,
  intervalTime,
  setIntervalTime,
  weekDaysRestriction,
  setWeekDaysRestriction,
}: NewInvitationValidityProps) => {
  const labels = useAppSelector(selectLabels);

  const timeIntervalArray = [
    {
      label: intervalNumber === "1" ? labels.day : labels.days,
      value: "days",
    },
    {
      label: intervalNumber === "1" ? labels.week : labels.weeks,
      value: "weeks",
    },
    {
      label: intervalNumber === "1" ? labels.month : labels.months,
      value: "months",
    },
  ];

  const daysOfWeekArray = [
    {
      label: labels.monday,
      value: "monday",
    },
    {
      label: labels.tuesday,
      value: "tuesday",
    },
    {
      label: labels.wednesday,
      value: "wednesday",
    },
    {
      label: labels.thursday,
      value: "thursday",
    },
    {
      label: labels.friday,
      value: "friday",
    },
    {
      label: labels.saturday,
      value: "saturday",
    },
    {
      label: labels.sunday,
      value: "sunday",
    },
  ];

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setState: (state: boolean) => void
  ) => {
    setState(event.target.checked);
  };

  return (
    <div
      className="w-100 border-box hidden flex flex-dir-col align-items-center gap-6 p-8"
      style={{
        backgroundColor: "white",
        border: "1px #e0e0e0 solid",
        borderRadius: 6,
      }}
    >
      <div className="w-100 flex align-items-center justify-content-between">
        <div className="w-50">
          <p className="t-nowrap body-text-lg font-w-500 title-color mb-4">
            {labels.validity}
          </p>
        </div>
        {multipleAccess && (
          <div className="w-50 flex align-items-center justify-content-end gap-2">
            <p className="t-nowrap body-text-md">{labels.restrictions}</p>
            <Switch
              checked={restrictions}
              onChange={(e) => handleChange(e, setRestrictions)}
            />
          </div>
        )}
      </div>
      <div className="w-100 border-box flex align-items-center gap-10">
        <div className="w-50">
          <p className="t-nowrap body-text-xs mb-3">
            {multipleAccess
              ? labels.invitation_valid_from
              : labels.valid_for_the_day}{" "}
            *
          </p>
          <DatePickerCustom value={date} setValue={setDate} />
        </div>
        {!multipleAccess ? (
          <div className="w-50 flex align-items-center gap-10">
            <div className="w-50">
              <p className="t-nowrap body-text-xs mb-3">{labels.from} *</p>
              <div className="flex align-items-center justify-content-end gap-1">
                <FormControl style={{ width: "50%" }}>
                  <Select
                    value={hour}
                    onChange={(event: SelectChangeEvent) =>
                      setHour(event.target.value as string)
                    }
                    size="small"
                  >
                    {hourArray.map((item, index) => (
                      <MenuItem
                        key={`${index}-${item?.label}`}
                        value={item?.value}
                      >
                        {item?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                :
                <FormControl style={{ width: "50%" }}>
                  <Select
                    value={minutes}
                    onChange={(event: SelectChangeEvent) =>
                      setMinutes(event.target.value as "+52" | "+1")
                    }
                    size="small"
                  >
                    {minutesArray.map((item, index) => (
                      <MenuItem
                        key={`${index}-${item?.label}`}
                        value={item?.value}
                      >
                        {item?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="w-50">
              <p className="t-nowrap body-text-xs mb-3">
                {labels.to}{" "}
                <span className="font-10">{`(${labels.hours_later})`}</span>
              </p>
              <FormControl fullWidth>
                <Select
                  value={hoursLater}
                  onChange={(event: SelectChangeEvent) =>
                    setHoursLater(event.target.value as string)
                  }
                  size="small"
                >
                  {hoursLaterArray.map((item, index) => (
                    <MenuItem
                      key={`${index}-${item?.label}`}
                      value={item?.value}
                    >
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        ) : (
          <div className="w-50 align-items-center gap-10">
            <p className="t-nowrap body-text-xs mb-3">{labels.until} *</p>
            <div className="flex align-items-center justify-content-end gap-4">
              <FormControl style={{ width: "50%" }}>
                <Select
                  value={intervalNumber}
                  onChange={(event: SelectChangeEvent) =>
                    setIntervalNumber(event.target.value as string)
                  }
                  size="small"
                >
                  {intervalTime === "days" &&
                    daysArray.map((item, index) => (
                      <MenuItem
                        key={`${index}-${item?.label}`}
                        value={item?.value}
                      >
                        {item?.label}
                      </MenuItem>
                    ))}
                  {intervalTime === "weeks" &&
                    weeksArray.map((item, index) => (
                      <MenuItem
                        key={`${index}-${item?.label}`}
                        value={item?.value}
                      >
                        {item?.label}
                      </MenuItem>
                    ))}
                  {intervalTime === "months" &&
                    monthsArray.map((item, index) => (
                      <MenuItem
                        key={`${index}-${item?.label}`}
                        value={item?.value}
                      >
                        {item?.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl style={{ width: "50%" }}>
                <Select
                  value={intervalTime}
                  onChange={(event: SelectChangeEvent) => {
                    setIntervalNumber("1");
                    setIntervalTime(event.target.value as string);
                  }}
                  size="small"
                >
                  {timeIntervalArray.map((item, index) => (
                    <MenuItem
                      key={`${index}-${item?.label}`}
                      value={item?.value}
                    >
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        )}
      </div>
      {multipleAccess && restrictions && (
        <div className="w-100 border-box flex align-items-center gap-10">
          <div className="w-50">
            <p className="t-nowrap body-text-xs mb-3">{labels.days_allowed}</p>
            <div className="w-100 flex align-items-center justify-content-evenly days-main-container">
              {daysOfWeekArray.map((day, index) => (
                <div
                  key={`${index}-${day.value}`}
                  className={`day-container pointer ${
                    weekDaysRestriction.includes(day.value) ? "selected" : ""
                  }`}
                  onClick={() => {
                    const dupWeekDays = [...weekDaysRestriction];
                    if (dupWeekDays.includes(day.value)) {
                      const indexValue = dupWeekDays.indexOf(day.value);
                      dupWeekDays.splice(indexValue, 1);
                      setWeekDaysRestriction(dupWeekDays);
                    } else {
                      dupWeekDays.push(day.value);
                      setWeekDaysRestriction(dupWeekDays);
                    }
                  }}
                >
                  <p className="font-13 select-none">{day.label}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="w-50 flex align-items-center gap-10">
            <div className="w-50">
              <p className="t-nowrap body-text-xs mb-3">{labels.from}</p>
              <div className="flex align-items-center justify-content-end gap-1">
                <FormControl style={{ width: "50%" }}>
                  <Select
                    value={hour}
                    onChange={(event: SelectChangeEvent) =>
                      setHour(event.target.value as string)
                    }
                    size="small"
                  >
                    {hourArray.map((item, index) => (
                      <MenuItem
                        key={`${index}-${item?.label}`}
                        value={item?.value}
                      >
                        {item?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                :
                <FormControl style={{ width: "50%" }}>
                  <Select
                    value={minutes}
                    onChange={(event: SelectChangeEvent) =>
                      setMinutes(event.target.value as "+52" | "+1")
                    }
                    size="small"
                  >
                    {minutesArray.map((item, index) => (
                      <MenuItem
                        key={`${index}-${item?.label}`}
                        value={item?.value}
                      >
                        {item?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="w-50">
              <p className="t-nowrap body-text-xs mb-3">
                {labels.to}{" "}
                <span className="font-10">{`(${labels.hours_later})`}</span>
              </p>
              <FormControl fullWidth>
                <Select
                  value={hoursLater}
                  onChange={(event: SelectChangeEvent) =>
                    setHoursLater(event.target.value as string)
                  }
                  size="small"
                >
                  {hoursLaterArray.map((item, index) => (
                    <MenuItem
                      key={`${index}-${item?.label}`}
                      value={item?.value}
                    >
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewInvitationValidity;
