import React from "react";
import DesktopHeader from "../../components/DesktopHeader";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import DesktopBody from "../../components/DesktopBody";
import TableComponent from "../../components/TableComponent";
import { TableData } from "../../components/TableComponent/TableComponent";
import api from "../../api";
import { CircularProgress } from "@mui/material";
import { DesktopFiltersProps } from "../../components/DesktopFilters/DesktopFilters";
import DesktopDropView from "../../components/DesktopDropView";
import NewInvitationForm from "../../components/NewInvitationForm";
import DesktopInvitationDetails from "../../components/DesktopInvitationDetails";
import { selectUser } from "../../slices/user";
import NewUnitForm from "../../components/NewUnitForm";

const Units = () => {
  const labels = useAppSelector(selectLabels);
  const user = useAppSelector(selectUser);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [tableDataToDisplay, setTableDataToDisplay] =
    React.useState<TableData | null>(null);
  const [searchFilter, setSearchFilter] = React.useState<string>("");
  const [showEditUnit, setShowEditUnit] = React.useState<boolean>(false);
  const [newUnit, setNewUnit] = React.useState<boolean>(false);
  const [selectedInvitationId, setSelectedInvitationId] = React.useState<
    number | null
  >(null);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalInvitations, setTotalInvitations] = React.useState<number>(0);

  const fixedPage = React.useMemo(() => {
    return page + 1;
  }, [page]);

  const propertyId = user?.property?.id;

  const filters: DesktopFiltersProps = {
    filterData: [
      {
        type: "search",
        value: searchFilter,
        setValue: setSearchFilter,
        disabled: isLoading,
        tooltip: "",
        function: () => {},
        array: [],
        size: "small",
        icon: "",
        label: "",
        multiDateValue: null,
        setMultiDateValue: () => {},
        filterWidth: 350,
      },
      {
        type: "function",
        value: "",
        setValue: () => {},
        disabled: isLoading,
        tooltip: labels.download_content,
        function: () => {},
        array: [],
        size: "small",
        icon: "fa-solid:file-download",
        label: "",
        multiDateValue: null,
        setMultiDateValue: () => {},
      },
      {
        type: "function",
        value: "",
        setValue: () => {},
        disabled: isLoading,
        tooltip: "",
        function: () => setNewUnit(true),
        array: [],
        size: "small",
        icon: "fa6-solid:circle-plus",
        label: "",
        multiDateValue: null,
        setMultiDateValue: () => {},
      },
    ],
  };

  React.useEffect(() => {
    getAccessKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels, fixedPage, rowsPerPage]);

  const getAccessKeys = async () => {
    try {
      setIsLoading(true);
      const response = await api.unitsRequest(
        Number(propertyId),
        fixedPage,
        rowsPerPage
      );
      if (response) {
        const totalRows = response?.meta?.pagination?.total;
        setTotalInvitations(totalRows);
        const dataForTable: TableData = {
          header: [
            {
              label: labels.name,
              isIcon: false,
              tooltipLabel: "",
            },
            {
              label: labels.access_points,
              isIcon: false,
              tooltipLabel: "",
            },
            {
              label: labels.users,
              isIcon: false,
              tooltipLabel: "",
            },
          ],
          body: response.data.map((item) => {
            return {
              row: item.id,
              cells: [
                {
                  label: [
                    {
                      mainLabel: item?.attributes?.name,
                      tooltipLabel: "",
                    },
                  ],
                  isIcon: false,
                  display: "flex-dir-row",
                },
                {
                  label: item?.attributes?.access_points?.data.map(
                    (accessPoint) => {
                      return {
                        mainLabel: accessPoint?.attributes?.name,
                        tooltipLabel: "",
                      };
                    }
                  ),
                  isIcon: false,
                  display: "flex-dir-col",
                },
                {
                  label: item?.attributes?.users?.data?.map((unitUser) => {
                    return {
                      mainLabel: unitUser?.attributes?.username,
                      tooltipLabel: "",
                    };
                  }),
                  isIcon: false,
                  display: "flex-dir-col",
                },
              ],
            };
          }),
        };
        setTableDataToDisplay(dataForTable);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-100">
      <DesktopHeader title={labels.units} />
      <DesktopBody hasFilters={true} filterData={filters.filterData}>
        <div className="w-100 h-100 px-8 pb-8 border-box">
          {isLoading ? (
            <div className="w-100 h-100 border-box flex align-items-center justify-content-center">
              <CircularProgress />
            </div>
          ) : (
            <TableComponent
              selectableRow={true}
              data={tableDataToDisplay}
              onPressRow={(id: number) => {
                setSelectedInvitationId(id);
                setShowEditUnit(true);
              }}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              total={totalInvitations}
              tableHeight={"calc(100vh - 188px)"}
            />
          )}
        </div>
      </DesktopBody>
      <DesktopDropView
        showView={showEditUnit}
        setShowView={setShowEditUnit}
        content={
          <div className="flex h-100 border-box" style={{ width: 500 }}>
            <div className="w-100 h-100 border-box p-8 scroll">
              <DesktopInvitationDetails invitationId={selectedInvitationId} />
            </div>
          </div>
        }
        extraFunc={false}
        title={labels.edit_unit}
      />
      <DesktopDropView
        showView={newUnit}
        setShowView={setNewUnit}
        content={
          <div className="flex h-100 border-box">
            <div className="h-100 border-box p-8 scroll">
              <NewUnitForm />
            </div>
          </div>
        }
        extraFunc={false}
        title={labels.new_unit}
      />
    </div>
  );
};

export default Units;
