import React from "react";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { selectUser, selectUserRole } from "../../slices/user";
import api, { CreateUserPayload } from "../../api";

interface UnitInfo {
  key: string;
  label: string;
  id: number;
}

interface NewUserFormProps {
  roleToCreate: number;
  propertyIdProp?: number | null;
  accessPointsProp?: number[];
  setShowForm: (showForm: boolean) => void;
  extraFunc: () => void;
}

const NewUserForm = ({
  roleToCreate,
  propertyIdProp,
  accessPointsProp,
  setShowForm,
  extraFunc,
}: NewUserFormProps) => {
  const labels = useAppSelector(selectLabels);
  const user = useAppSelector(selectUser);
  const userRole = useAppSelector(selectUserRole);

  const [countryCode, setCountryCode] = React.useState<"+52" | "+1">("+52");
  const [userName, setUserName] = React.useState<string>("");
  const [userPhone, setUserPhone] = React.useState<string>("");
  const [userEmail, setUserEmail] = React.useState<string>("");
  const [selectedAccessPoints, setSelectedAccessPoints] = React.useState<
    string[]
  >([]);
  const [unitInputValue, setUnitInputValue] = React.useState<string>("");
  const [unit, setUnit] = React.useState<UnitInfo | null>(null);
  const [unitsArray, setUnitsArray] = React.useState<UnitInfo[]>([]);

  const propertyId = user?.property?.id;
  const companyId = user?.company?.id;
  const unitForUsers = user?.unit?.id;

  const defaultUnitsProps = {
    options: unitsArray ? unitsArray : [],
    getOptionLabel: (option: UnitInfo) => option.label,
  };

  const countryCodeArray = [
    {
      label: "MX +52",
      value: "+52",
    },
    {
      label: "US +1",
      value: "+1",
    },
  ];

  const accessPointsArray =
    user?.access_points.map((item) => {
      return {
        name: item.name,
        venue: item.vanue_name,
        value: `${item.id}`,
      };
    }) || [];

  const accessPointsForUsers =
    user?.access_points.map((item) => item?.id) || [];

  React.useEffect(() => {
    getUnitsByProperty();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyId, roleToCreate]);

  const getUnitsByProperty = async () => {
    if (propertyId && roleToCreate === 3) {
      try {
        const response = await api.unitsRequest(Number(propertyId), 1, 100);
        if (response) {
          const array: UnitInfo[] = response.data.map((item, index) => {
            return {
              key: `${item?.id}-${index}`,
              label: item.attributes.name,
              id: item?.id,
            };
          });
          setUnitsArray(array);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const createUser = async () => {
    let payload: CreateUserPayload = {};
    if (roleToCreate === 3) {
      //PAYLOAD FOR HOST USER
      payload = {
        username: userName,
        email: userEmail,
        phone: countryCode + userPhone,
        unit: unit?.id,
        property: propertyId ? [propertyId] : null,
        company: companyId,
        password: "temporary",
        access_points: accessPointsForUsers,
        created_by_user: user?.username,
      };
    }
    if (roleToCreate === 5) {
      //PAYLOAD FOR SECONDARY USERS
      payload = {
        username: userName,
        email: userEmail,
        phone: countryCode + userPhone,
        unit: unitForUsers,
        property: propertyId ? [propertyId] : null,
        company: companyId,
        password: "temporary",
        access_points: accessPointsForUsers,
        created_by_user: user?.username,
      };
    }
    if (roleToCreate === 4) {
      //PAYLOAD FOR SECURITY GUARDS
      payload = {
        username: userName,
        email: userEmail,
        property: propertyId ? [propertyId] : null,
        company: companyId,
        password: "temporary",
        access_points: selectedAccessPoints.map((item) => Number(item)),
        created_by_user: user?.username,
      };
    }
    if (roleToCreate === 6) {
      //PAYLOAD FOR ADMINS
      if (userRole === "ciso") {
        payload = {
          username: userName,
          email: userEmail,
          phone: countryCode + userPhone,
          property: propertyIdProp ? [propertyIdProp] : null,
          company: companyId,
          password: "temporary",
          access_points: accessPointsProp,
          created_by_user: user?.username,
        };
      } else {
        payload = {
          username: userName,
          email: userEmail,
          phone: countryCode + userPhone,
          property: propertyId ? [propertyId] : null,
          company: companyId,
          password: "temporary",
          access_points: accessPointsForUsers,
          created_by_user: user?.username,
        };
      }
    }
    try {
      const response = await api.createUser(payload);
      if (response) {
        const addRoleToUser = await api.addUserRole(
          response?.user?.id,
          roleToCreate
        );
        if (addRoleToUser) {
          const sendEmail = await api.sendConfirmationEmail({
            username: userName,
            role: roleToCreate,
            email: userEmail,
          });
          if (sendEmail) {
            setShowForm(false);
            extraFunc();
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="border-box flex flex-dir-col gap-4" style={{ width: 660 }}>
      <div
        className="w-100 border-box hidden flex flex-dir-col align-items-center gap-6 p-8"
        style={{
          backgroundColor: "white",
          border: "1px #e0e0e0 solid",
          borderRadius: 6,
        }}
      >
        <div className="w-100 border-box flex align-items-center gap-10">
          <div className="w-50">
            <p className="t-nowrap body-text-xs mb-3">{labels.full_name} *</p>
            <TextField
              fullWidth
              placeholder={labels.enter_your_name}
              value={userName}
              size="small"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setUserName(event.target.value);
              }}
            />
          </div>
          {roleToCreate === 4 && (
            <div className="w-50">
              <p className="t-nowrap body-text-xs mb-3">{labels.email} *</p>
              <TextField
                fullWidth
                placeholder={"email@example.com"}
                value={userEmail}
                size="small"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setUserEmail(event.target.value);
                }}
              />
            </div>
          )}
          {roleToCreate !== 4 && (
            <div className="w-50">
              <p className="t-nowrap body-text-xs mb-3">{labels.telephone} *</p>
              <div className="flex align-items-center justify-content-end gap-4">
                <FormControl style={{ width: 150 }}>
                  <Select
                    value={countryCode}
                    onChange={(event: SelectChangeEvent) =>
                      setCountryCode(event.target.value as "+52" | "+1")
                    }
                    size="small"
                  >
                    {countryCodeArray.map((item, index) => (
                      <MenuItem
                        key={`${index}-${item?.label}`}
                        value={item?.value}
                      >
                        {item?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  type="number"
                  placeholder={labels.ten_digit_number}
                  value={userPhone}
                  size="small"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setUserPhone(event.target.value);
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className="w-100 border-box flex align-items-center gap-10">
          {roleToCreate === 4 && (
            <>
              <div className="w-50">
                <p className="t-nowrap body-text-xs mb-3">
                  {labels.access_points} *
                </p>
                <div className="flex flex-dir-col gap-4 access-points-container">
                  {accessPointsArray.map((item, index) => (
                    <div
                      key={`${index}-${item?.value}`}
                      className="w-100 flex align-items-center gap-2"
                    >
                      <Checkbox
                        checked={selectedAccessPoints.includes(item?.value)}
                        onChange={() => {
                          const dupSelAccessPoints = [...selectedAccessPoints];
                          if (dupSelAccessPoints.includes(item?.value)) {
                            const indexValue = dupSelAccessPoints.indexOf(
                              item?.value
                            );
                            dupSelAccessPoints.splice(indexValue, 1);
                          } else {
                            dupSelAccessPoints.push(item?.value);
                          }
                          setSelectedAccessPoints(dupSelAccessPoints);
                        }}
                      />
                      <div>
                        <p className="t-nowrap body-text-sm">{item?.name}</p>
                        <p className="t-nowrap body-text-xs title-color">
                          {item?.venue}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-50"></div>
            </>
          )}
          {roleToCreate !== 4 && (
            <>
              <div className="w-50">
                <p className="t-nowrap body-text-xs mb-3">{labels.email} *</p>
                <TextField
                  fullWidth
                  placeholder={"email@example.com"}
                  value={userEmail}
                  size="small"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setUserEmail(event.target.value);
                  }}
                />
              </div>
              <div className="w-50">
                {roleToCreate === 3 && (
                  <>
                    <p className="t-nowrap body-text-xs mb-3">
                      {labels.unit} *
                    </p>

                    <Autocomplete
                      {...defaultUnitsProps}
                      autoComplete
                      value={unit}
                      onChange={(event: any, newValue: UnitInfo | null) => {
                        setUnit(newValue);
                      }}
                      inputValue={unitInputValue}
                      onInputChange={(event, newInputValue) => {
                        setUnitInputValue(newInputValue);
                      }}
                      id="Origen refactor"
                      size="small"
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div
        className="w-100 border-box hidden flex flex-dir-col align-items-center gap-6 p-8"
        style={{
          backgroundColor: "white",
          border: "1px #e0e0e0 solid",
          borderRadius: 6,
        }}
      >
        <div className="w-100" style={{ textAlign: "center" }}>
          <p className="t-nowrap body-text-sm">* {labels.mandatory_fields}</p>
        </div>
        <div className="w-100" style={{ textAlign: "center" }}>
          <Button variant="contained" disableElevation onClick={createUser}>
            {roleToCreate === 3 && labels.create_user}
            {roleToCreate === 5 && labels.create_user}
            {roleToCreate === 6 && labels.create_admin}
            {roleToCreate === 4 && labels.create_sec_guard}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewUserForm;
