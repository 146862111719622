import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";
import IconifyIcon from "../IconifyIcon";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import "./table-component.css";

export interface TableData {
  header: { label: string; isIcon: boolean; tooltipLabel: string }[];
  body: {
    row: number;
    cells: {
      label: { mainLabel: string; tooltipLabel: string }[];
      isIcon: boolean;
      display: "flex-dir-row" | "flex-dir-col";
    }[];
  }[];
}

interface TableComponentProps {
  selectableRow: boolean;
  data: TableData | null;
  onPressRow: (id: number) => void;
  page: number;
  setPage: (page: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (rowsPerPage: number) => void;
  total: number;
  tableHeight: string;
}

const TableComponent = ({
  selectableRow,
  data,
  onPressRow,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  total,
  tableHeight,
}: TableComponentProps) => {
  const labels = useAppSelector(selectLabels);

  const [isScrolledToBottom, setIsScrolledToBottom] = React.useState(false);

  const tableContainerRef: any = React.useRef(null);

  const handleScroll = () => {
    if (tableContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        tableContainerRef.current;
      setIsScrolledToBottom(scrollTop + clientHeight >= scrollHeight);
    }
  };

  React.useEffect(() => {
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      tableContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (tableContainer) {
        tableContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box
      style={{
        width: "100%",
        boxSizing: "border-box",
        border: "1px #e0e0e0 solid",
        borderRadius: 6,
        overflow: "hidden",
      }}
    >
      <TableContainer
        ref={tableContainerRef}
        sx={{ maxHeight: tableHeight, minHeight: tableHeight }}
        style={{ overflowX: "auto", backgroundColor: "#f8f8f8" }}
      >
        <Table stickyHeader style={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              {data?.header.map((item, index) => (
                <TableCell
                  key={`${index}-${item?.label}`}
                  align="center"
                  className="p-5"
                  style={{ backgroundColor: "#f0f0f0" }}
                >
                  {item?.isIcon ? (
                    item?.tooltipLabel ? (
                      <Tooltip title={item?.tooltipLabel} arrow placement="top">
                        <div>
                          <IconifyIcon
                            icon={item?.label}
                            className="title-color"
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      <IconifyIcon icon={item?.label} className="title-color" />
                    )
                  ) : item?.tooltipLabel ? (
                    <Tooltip title={item?.tooltipLabel} arrow placement="top">
                      <div>
                        <p>{item?.label}</p>
                      </div>
                    </Tooltip>
                  ) : (
                    <p>{item?.label}</p>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.body.map((item, index) => (
              <TableRow
                key={`${index}-${item?.row}`}
                className={selectableRow ? "body-row" : "no-selectable-row"}
                onClick={selectableRow ? () => onPressRow(item?.row) : () => {}}
              >
                {item.cells.map((cellItem, cellIndex) => (
                  <TableCell
                    key={`${cellIndex}-${cellItem?.label}`}
                    align="center"
                    className="p-5"
                  >
                    {cellItem?.isIcon ? (
                      cellItem?.label.some(
                        (cellLabel) => cellLabel?.tooltipLabel !== ""
                      ) ? (
                        <div
                          className={`flex ${cellItem?.display} justify-content-center`}
                        >
                          {cellItem.label.map((cellLabel, cellLabelIndex) => (
                            <Tooltip
                              key={`${cellLabelIndex}`}
                              title={cellLabel?.tooltipLabel}
                              arrow
                              placement="top"
                              style={{
                                marginRight:
                                  cellItem.label.length > 1
                                    ? cellLabelIndex ===
                                      cellItem.label.length - 1
                                      ? 0
                                      : 8
                                    : 0,
                              }}
                            >
                              <div className="flex align-items-center title-color">
                                <IconifyIcon icon={cellLabel?.mainLabel} />
                              </div>
                            </Tooltip>
                          ))}
                        </div>
                      ) : (
                        <div
                          className={`flex ${cellItem?.display} justify-content-center`}
                        >
                          {cellItem.label.map((cellLabel, cellLabelIndex) => (
                            <IconifyIcon
                              key={`${cellLabelIndex}`}
                              icon={cellLabel?.mainLabel}
                              className="title-color"
                            />
                          ))}
                        </div>
                      )
                    ) : cellItem?.label.some(
                        (cellLabel) => cellLabel?.tooltipLabel !== ""
                      ) ? (
                      <div
                        className={`flex ${cellItem?.display} justify-content-center`}
                      >
                        {cellItem.label.map((cellLabel, cellLabelIndex) => (
                          <Tooltip
                            key={`${cellLabelIndex}`}
                            title={cellLabel?.tooltipLabel}
                            arrow
                            placement="top"
                          >
                            <div>
                              <p className="body-text-md">
                                {cellLabel?.mainLabel}
                              </p>
                            </div>
                          </Tooltip>
                        ))}
                      </div>
                    ) : (
                      <div
                        className={`flex ${cellItem?.display} justify-content-center`}
                      >
                        {cellItem.label.map((cellLabel, cellLabelIndex) => (
                          <p key={`${cellLabelIndex}`} className="body-text-xs">
                            {cellLabel?.mainLabel}
                          </p>
                        ))}
                      </div>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={labels.rows_per_page}
        labelDisplayedRows={function defaultLabelDisplayedRows({
          from,
          to,
          count,
        }) {
          return `${from}–${to} ${labels.of} ${
            count !== -1 ? count : `more than ${to}`
          }`;
        }}
        style={{ borderTop: isScrolledToBottom ? "none" : "1px #e0e0e0 solid" }}
      />
    </Box>
  );
};

export default TableComponent;
