import React from "react";
import DesktopHeader from "../../components/DesktopHeader";
import { useAppSelector, useDebounce } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import DesktopBody from "../../components/DesktopBody";
import TableComponent from "../../components/TableComponent";
import { TableData } from "../../components/TableComponent/TableComponent";
import api from "../../api";
import { CircularProgress } from "@mui/material";
import { DesktopFiltersProps } from "../../components/DesktopFilters/DesktopFilters";
import { selectUser, selectUserRole } from "../../slices/user";
import DesktopDropView from "../../components/DesktopDropView";
import NewUserForm from "../../components/NewUserForm";
import { PropertyArray } from "../../api/models/property-model";
import dayjs from "dayjs";

const NetworkAdmins = () => {
  const labels = useAppSelector(selectLabels);
  const user = useAppSelector(selectUser);
  const userRole = useAppSelector(selectUserRole);

  const headers = [
    { label: "ID", key: "ID" },
    { label: labels.name, key: labels.name },
    { label: labels.telephone, key: labels.phone },
    { label: labels.email, key: labels.email },
    { label: labels.unit, key: labels.unit },
    { label: labels.property, key: labels.property },
    { label: labels.created_by, key: labels.created_by },
  ];

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [tableDataToDisplay, setTableDataToDisplay] =
    React.useState<TableData | null>(null);
  const [searchFilter, setSearchFilter] = React.useState<string>("");
  const [newUser, setNewUser] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalUsers, setTotalUsers] = React.useState<number>(0);
  const [selectedProperty, setSelectedProperty] = React.useState<string | null>(
    null
  );
  const [properties, setProperties] = React.useState<PropertyArray | null>(
    null
  );
  const [dataTable, setDataTable] = React.useState<any[]>([]);

  const debouncedSearchText = useDebounce(searchFilter, 500);

  const companyId = user?.company?.id;

  const fixedPage = React.useMemo(() => {
    return page + 1;
  }, [page]);

  const accessPointsArray = user?.access_points
    ? user.access_points.map((item) => {
        return {
          label: item?.name,
          value: `${item.id}`,
        };
      })
    : [];

  accessPointsArray.unshift({
    label: labels.all_access_points,
    value: "0",
  });

  const accessPointsForNewUserProp =
    properties?.data
      .filter((item) => item?.id === Number(selectedProperty))[0]
      .attributes?.access_points?.data.map((accPoint) => accPoint?.id) || [];

  console.log("properties", properties);

  const propertiesArray = properties
    ? properties.data.map((item) => {
        return {
          label: item?.attributes?.name || "",
          value: `${item.id}`,
        };
      })
    : [];

  const currentDate = dayjs().format("DD-MM-YY");

  const filters: DesktopFiltersProps = {
    filterData:
      userRole === "ciso"
        ? [
            {
              type: "selector",
              value: selectedProperty,
              setValue: setSelectedProperty,
              disabled: isLoading,
              tooltip: "",
              function: () => {},
              array: propertiesArray,
              size: "small",
              icon: "",
              label: labels.access_points,
              multiDateValue: null,
              setMultiDateValue: () => {},
              filterWidth: 350,
            },
            {
              type: "search",
              value: searchFilter,
              setValue: setSearchFilter,
              disabled: isLoading,
              tooltip: "",
              function: () => {},
              array: [],
              size: "small",
              icon: "",
              label: "",
              multiDateValue: null,
              setMultiDateValue: () => {},
              filterWidth: 350,
            },
            {
              type: "function",
              value: "",
              setValue: () => {},
              disabled: isLoading,
              tooltip: labels.download_content,
              function: () => {},
              array: [],
              size: "small",
              icon: "fa-solid:file-download",
              label: "",
              multiDateValue: null,
              setMultiDateValue: () => {},
              downloadable: true,
              downloadData: dataTable,
              downloadHeader: headers,
              downloadFilename: `${labels.admins} ${currentDate}`,
            },
            {
              type: "function",
              value: "",
              setValue: () => {},
              disabled: isLoading,
              tooltip: "",
              function: () => setNewUser(true),
              array: [],
              size: "small",
              icon: "fa6-solid:circle-plus",
              label: "",
              multiDateValue: null,
              setMultiDateValue: () => {},
            },
          ]
        : [
            {
              type: "search",
              value: searchFilter,
              setValue: setSearchFilter,
              disabled: isLoading,
              tooltip: "",
              function: () => {},
              array: [],
              size: "small",
              icon: "",
              label: "",
              multiDateValue: null,
              setMultiDateValue: () => {},
              filterWidth: 350,
            },
            {
              type: "function",
              value: "",
              setValue: () => {},
              disabled: isLoading,
              tooltip: labels.download_content,
              function: () => {},
              array: [],
              size: "small",
              icon: "fa-solid:file-download",
              label: "",
              multiDateValue: null,
              setMultiDateValue: () => {},
              downloadable: true,
              downloadData: dataTable,
              downloadHeader: headers,
              downloadFilename: `${labels.admins} ${currentDate}`,
            },
            {
              type: "function",
              value: "",
              setValue: () => {},
              disabled: isLoading,
              tooltip: "",
              function: () => setNewUser(true),
              array: [],
              size: "small",
              icon: "fa6-solid:circle-plus",
              label: "",
              multiDateValue: null,
              setMultiDateValue: () => {},
            },
          ],
  };

  React.useEffect(() => {
    getUsers(debouncedSearchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userRole,
    labels,
    fixedPage,
    rowsPerPage,
    debouncedSearchText,
    selectedProperty,
  ]);

  const getUsers = async (debouncedSearchText: string) => {
    try {
      setIsLoading(true);
      let response;
      if (userRole === "ciso") {
        if (selectedProperty) {
          response = await api.usersListRequest(
            debouncedSearchText,
            Number(selectedProperty),
            null,
            6
          );
        }
      } else {
        response = await api.usersListRequest(
          debouncedSearchText,
          user?.property?.id,
          null,
          6
        );
      }
      if (response) {
        // const dataForDownloadableTable = response.map((item) => {
        //   return {
        //     ID: `${item?.id}`,
        //     [labels.name]: item?.username ? `${item?.username}` : "",
        //     [labels.telephone]: item?.phone ? `${item?.phone}` : "",
        //     [labels.email]: item?.email ? `${item?.email}` : "",
        //     [labels.unit]: item?.unit?.name ? `${item?.unit?.name}` : "",
        //     [labels.property]: item?.property?.name
        //       ? `${item?.property?.name}`
        //       : "",
        //     [labels.created_by]: item?.created_by_user
        //       ? `${item?.created_by_user}`
        //       : "",
        //   };
        // });
        setDataTable([]);
        setTotalUsers(0);
        const dataForTable: TableData = {
          header: [
            {
              label: labels.name,
              isIcon: false,
              tooltipLabel: "",
            },
            {
              label: labels.email,
              isIcon: false,
              tooltipLabel: "",
            },
            {
              label: labels.telephone,
              isIcon: false,
              tooltipLabel: "",
            },
            {
              label: labels.created_by,
              isIcon: false,
              tooltipLabel: "",
            },
          ],
          body: response.map((item) => {
            return {
              row: item.id,
              cells: [
                {
                  label: [
                    {
                      mainLabel: item?.username,
                      tooltipLabel: "",
                    },
                  ],
                  isIcon: false,
                  display: "flex-dir-row",
                },
                {
                  label: [
                    {
                      mainLabel: item?.email,
                      tooltipLabel: "",
                    },
                  ],
                  isIcon: false,
                  display: "flex-dir-row",
                },
                {
                  label: [
                    {
                      mainLabel: item?.phone,
                      tooltipLabel: "",
                    },
                  ],
                  isIcon: false,
                  display: "flex-dir-col",
                },
                {
                  label: [
                    {
                      mainLabel: item?.created_by_user,
                      tooltipLabel: "",
                    },
                  ],
                  isIcon: false,
                  display: "flex-dir-col",
                },
              ],
            };
          }),
        };
        setTableDataToDisplay(dataForTable);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const getProperties = async () => {
    if (companyId) {
      try {
        setIsLoading(true);
        const propertyResponse = await api.propertiesByCompanyRequest(
          companyId
        );
        if (propertyResponse) {
          setProperties(propertyResponse);
          setSelectedProperty(`${propertyResponse?.data[0]?.id}`);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="w-100">
      <DesktopHeader title={labels.admins} />
      <DesktopBody hasFilters={true} filterData={filters.filterData}>
        <div className="w-100 h-100 px-8 pb-8 border-box">
          {isLoading ? (
            <div className="w-100 h-100 border-box flex align-items-center justify-content-center">
              <CircularProgress />
            </div>
          ) : (
            <TableComponent
              selectableRow={false}
              data={tableDataToDisplay}
              onPressRow={() => {}}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              total={totalUsers}
              tableHeight={"calc(100vh - 188px)"}
            />
          )}
        </div>
      </DesktopBody>
      <DesktopDropView
        showView={newUser}
        setShowView={setNewUser}
        content={
          <div className="flex h-100 border-box">
            <div className="h-100 border-box p-8 scroll">
              {userRole === "ciso" ? (
                <NewUserForm
                  roleToCreate={6}
                  propertyIdProp={Number(selectedProperty)}
                  accessPointsProp={accessPointsForNewUserProp}
                  setShowForm={setNewUser}
                  extraFunc={() => getUsers(debouncedSearchText)}
                />
              ) : (
                <NewUserForm
                  roleToCreate={6}
                  setShowForm={setNewUser}
                  extraFunc={() => getUsers(debouncedSearchText)}
                />
              )}
            </div>
          </div>
        }
        extraFunc={false}
        title={labels.new_admin}
      />
    </div>
  );
};

export default NetworkAdmins;
