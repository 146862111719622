import React from "react";
import DesktopHeader from "../../components/DesktopHeader";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import DesktopBody from "../../components/DesktopBody";
import TableComponent from "../../components/TableComponent";
import { TableData } from "../../components/TableComponent/TableComponent";
import api from "../../api";
import { CircularProgress } from "@mui/material";
import { DesktopFiltersProps } from "../../components/DesktopFilters/DesktopFilters";
import { selectUser } from "../../slices/user";
import DesktopDropView from "../../components/DesktopDropView";
import NewUserForm from "../../components/NewUserForm";

const HostUsers = () => {
  const labels = useAppSelector(selectLabels);
  const user = useAppSelector(selectUser);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [tableDataToDisplay, setTableDataToDisplay] =
    React.useState<TableData | null>(null);
  const [searchFilter, setSearchFilter] = React.useState<string>("");
  const [newUser, setNewUser] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalUsers, setTotalUsers] = React.useState<number>(0);

  const fixedPage = React.useMemo(() => {
    return page + 1;
  }, [page]);

  const accessPointsArray = user?.access_points
    ? user.access_points.map((item) => {
        return {
          label: item?.name,
          value: `${item.id}`,
        };
      })
    : [];

  accessPointsArray.unshift({
    label: labels.all_access_points,
    value: "0",
  });

  const filters: DesktopFiltersProps = {
    filterData: [
      {
        type: "search",
        value: searchFilter,
        setValue: setSearchFilter,
        disabled: isLoading,
        tooltip: "",
        function: () => {},
        array: [],
        size: "small",
        icon: "",
        label: "",
        multiDateValue: null,
        setMultiDateValue: () => {},
        filterWidth: 350,
      },
      {
        type: "function",
        value: "",
        setValue: () => {},
        disabled: isLoading,
        tooltip: labels.download_content,
        function: () => {},
        array: [],
        size: "small",
        icon: "fa-solid:file-download",
        label: "",
        multiDateValue: null,
        setMultiDateValue: () => {},
      },
      {
        type: "function",
        value: "",
        setValue: () => {},
        disabled: isLoading,
        tooltip: "",
        function: () => setNewUser(true),
        array: [],
        size: "small",
        icon: "fa6-solid:circle-plus",
        label: "",
        multiDateValue: null,
        setMultiDateValue: () => {},
      },
    ],
  };

  React.useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels, fixedPage, rowsPerPage]);

  console.log("property", user);

  const getUsers = async () => {
    try {
      setIsLoading(true);
      const response = await api.usersListRequest(
        "",
        user?.property?.id,
        null,
        3,
        5
      );
      if (response) {
        setTotalUsers(0);
        const dataForTable: TableData = {
          header: [
            {
              label: labels.name,
              isIcon: false,
              tooltipLabel: "",
            },
            {
              label: labels.unit,
              isIcon: false,
              tooltipLabel: "",
            },
            {
              label: labels.email,
              isIcon: false,
              tooltipLabel: "",
            },
            {
              label: labels.telephone,
              isIcon: false,
              tooltipLabel: "",
            },
            {
              label: labels.role,
              isIcon: false,
              tooltipLabel: "",
            },
            {
              label: labels.created_by,
              isIcon: false,
              tooltipLabel: "",
            },
          ],
          body: response.map((item) => {
            return {
              row: item.id,
              cells: [
                {
                  label: [
                    {
                      mainLabel: item?.username,
                      tooltipLabel: "",
                    },
                  ],
                  isIcon: false,
                  display: "flex-dir-row",
                },
                {
                  label: [
                    {
                      mainLabel: item?.unit?.name,
                      tooltipLabel: "",
                    },
                  ],
                  isIcon: false,
                  display: "flex-dir-row",
                },
                {
                  label: [
                    {
                      mainLabel: item?.email,
                      tooltipLabel: "",
                    },
                  ],
                  isIcon: false,
                  display: "flex-dir-row",
                },
                {
                  label: [
                    {
                      mainLabel: item?.phone,
                      tooltipLabel: "",
                    },
                  ],
                  isIcon: false,
                  display: "flex-dir-col",
                },
                {
                  label: [
                    {
                      mainLabel:
                        item?.role?.name === "host"
                          ? labels.main_user
                          : item?.role?.name === "secondary_user"
                          ? labels.secondary_user
                          : "",
                      tooltipLabel: "",
                    },
                  ],
                  isIcon: false,
                  display: "flex-dir-col",
                },
                {
                  label: [
                    {
                      mainLabel: item?.created_by_user,
                      tooltipLabel: "",
                    },
                  ],
                  isIcon: false,
                  display: "flex-dir-col",
                },
              ],
            };
          }),
        };
        setTableDataToDisplay(dataForTable);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-100">
      <DesktopHeader title={labels.users} />
      <DesktopBody hasFilters={true} filterData={filters.filterData}>
        <div className="w-100 h-100 px-8 pb-8 border-box">
          {isLoading ? (
            <div className="w-100 h-100 border-box flex align-items-center justify-content-center">
              <CircularProgress />
            </div>
          ) : (
            <TableComponent
              selectableRow={false}
              data={tableDataToDisplay}
              onPressRow={() => {}}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              total={totalUsers}
              tableHeight={"calc(100vh - 188px)"}
            />
          )}
        </div>
      </DesktopBody>
      <DesktopDropView
        showView={newUser}
        setShowView={setNewUser}
        content={
          <div className="flex h-100 border-box">
            <div className="h-100 border-box p-8 scroll">
              <NewUserForm
                roleToCreate={3}
                setShowForm={setNewUser}
                extraFunc={() => {}}
              />
            </div>
          </div>
        }
        extraFunc={false}
        title={labels.new_user}
      />
    </div>
  );
};

export default HostUsers;
