import React from "react";
import DesktopHeader from "../../components/DesktopHeader";
import { useAppSelector, useDebounce } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import DesktopBody from "../../components/DesktopBody";
import { DesktopFiltersProps } from "../../components/DesktopFilters/DesktopFilters";
import {
  CircularProgress,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import api from "../../api";
import "./properties.css";
import { selectUser } from "../../slices/user";
import { PropertyArray } from "../../api/models/property-model";
import IconifyIcon from "../../components/IconifyIcon";
import { LicenceData } from "../../api/models/licence-model";
import dayjs from "dayjs";
import chartMock from "../../assets/chart_mock.png";

const Properties = () => {
  const labels = useAppSelector(selectLabels);
  const user = useAppSelector(selectUser);

  const googleAPIKey = "AIzaSyCxuo6aFRHzt74w5hEeiHcTEpL40TzOaXk";
  const companyId = user?.company?.id;

  const headers = [
    { label: "ID", key: "ID" },
    { label: labels.name, key: labels.name },
    { label: labels.address, key: labels.address },
    { label: labels.type, key: labels.type },
    { label: labels.admins, key: labels.admins },
    { label: labels.users, key: labels.users },
    { label: labels.secondary_users, key: labels.secondary_users },
    { label: labels.security_guards, key: labels.security_guards },
    { label: labels.units, key: labels.units },
  ];

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleAPIKey,
  });

  const mapStyles = {
    height: "100%",
    width: "100%",
  };

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [searchFilter, setSearchFilter] = React.useState<string>("");
  const [coordinates, setCoordinates] = React.useState<any>([]);
  const [map, setMap] = React.useState<any>(null);
  const [properties, setProperties] = React.useState<PropertyArray | null>(
    null
  );
  const [selectedLicense, setSelectedLicense] =
    React.useState<LicenceData | null>(null);
  const [showLicenseDetails, setShowLicenseDetails] =
    React.useState<boolean>(false);
  const [selectedPropertyName, setSelectedPropertyName] =
    React.useState<string>("");
  const [showInteligence, setShowInteligence] = React.useState<boolean>(false);
  const [dataTable, setDataTable] = React.useState<any[]>([]);

  const coordinatesArray = [
    {
      lat: 19.4,
      lng: -99.2039381,
    },
    {
      lat: 20.57,
      lng: -103.3,
    },
  ];

  const debouncedSearchText = useDebounce(searchFilter, 500);

  const currentDate = dayjs().format("DD-MM-YY");

  const filters: DesktopFiltersProps = {
    filterData: [
      {
        type: "search",
        value: searchFilter,
        setValue: setSearchFilter,
        disabled: isLoading,
        tooltip: "",
        function: () => {},
        array: [],
        size: "small",
        icon: "",
        label: "",
        multiDateValue: null,
        setMultiDateValue: () => {},
        filterWidth: 350,
      },
      {
        type: "function",
        value: "",
        setValue: () => {},
        disabled: isLoading,
        tooltip: labels.download_content,
        function: () => {},
        array: [],
        size: "small",
        icon: "fa-solid:file-download",
        label: "",
        multiDateValue: null,
        setMultiDateValue: () => {},
        downloadable: true,
        downloadData: dataTable,
        downloadHeader: headers,
        downloadFilename: `${labels.properties} ${currentDate}`,
      },
    ],
  };

  const onMapLoad = React.useCallback((map: any) => {
    setMap(map);
  }, []);

  React.useEffect(() => {
    if (map && coordinates.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      coordinates.forEach((coordinate: any) => {
        bounds.extend(
          new window.google.maps.LatLng(coordinate.lat, coordinate.lng)
        );
      });
      map.fitBounds(bounds);
    }
  }, [map, coordinates]);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  React.useEffect(() => {
    getProperties(debouncedSearchText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, debouncedSearchText]);

  const getProperties = async (debouncedSearchText: string) => {
    if (companyId) {
      try {
        setIsLoading(true);
        const response = await api.propertiesByCompanyRequest(
          companyId,
          debouncedSearchText
        );
        if (response) {
          const dataForTable = response.data.map((item) => {
            return {
              ID: `${item?.id}`,
              [labels.name]: `${item?.attributes?.name}`,
              [labels.address]: `${item?.attributes?.address}`,
              [labels.type]: `${
                item?.attributes?.type === "Corporate"
                  ? labels.corporate
                  : item?.attributes?.type === "Industrial"
                  ? labels.industrial
                  : item?.attributes?.type === "Residential"
                  ? labels.residential
                  : ""
              }`,
              [labels.admins]: `${
                item?.attributes?.users?.data.filter(
                  (userData) => userData?.attributes?.role?.data?.id === 6
                ).length
              }`,
              [labels.users]: `${
                item?.attributes?.users?.data.filter(
                  (userData) => userData?.attributes?.role?.data?.id === 3
                ).length
              }`,
              [labels.secondary_users]: `${
                item?.attributes?.users?.data.filter(
                  (userData) => userData?.attributes?.role?.data?.id === 5
                ).length
              }`,
              [labels.security_guards]: `${
                item?.attributes?.users?.data.filter(
                  (userData) => userData?.attributes?.role?.data?.id === 4
                ).length
              }`,
              [labels.units]: `${item?.attributes?.units?.data.length}`,
            };
          });
          setDataTable(dataForTable);
          setProperties(response);
          const getCoordinates = coordinatesArray?.map((element) => ({
            lat: element.lat,
            lng: element.lng,
          }));
          setCoordinates(getCoordinates);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="w-100">
      <Modal open={showInteligence} onClose={() => setShowInteligence(false)}>
        <div
          className="w-100 h-100 flex align-items-center justify-content-center"
          onClick={() => setShowInteligence(false)}
        >
          <Paper
            className="w-75 border-box flex flex-dir-col gap-6 p-8"
            style={{ height: "90vh" }}
          >
            <div className="w-100">
              <p className="t-nowrap body-text-lg font-w-500 title-color mb-2">
                {labels.intelligence} - {selectedPropertyName}
              </p>
            </div>
            <div
              className="w-100 h-100"
              style={{
                borderRadius: 4,
                overflow: "hidden",
                backgroundColor: "#f8f8f8",
                boxSizing: "border-box",
              }}
            >
              <img
                src={chartMock}
                alt="loaded"
                width={"100%"}
                height={"100%"}
                style={{ objectFit: "contain" }}
              />
            </div>
          </Paper>
        </div>
      </Modal>
      <Modal
        open={showLicenseDetails}
        onClose={() => setShowLicenseDetails(false)}
      >
        <div
          className="w-100 h-100 flex align-items-center justify-content-center"
          onClick={() => setShowLicenseDetails(false)}
        >
          <Paper
            className="border-box flex flex-dir-col gap-6 p-8"
            style={{ width: 350 }}
          >
            <div className="w-100">
              <p className="t-nowrap body-text-lg font-w-500 title-color mb-6">
                {labels.license_details}
              </p>
            </div>
            <div className="w-100 mb-4">
              <p className="t-nowrap body-text-sm subtitle-color mr-4 mb-2">
                {labels.property}
              </p>
              <p className="t-nowrap body-text-sm subtitle-color font-w-500">
                {selectedPropertyName}
              </p>
            </div>
            <div className="w-100 mb-4">
              <p className="t-nowrap body-text-sm subtitle-color mr-4 mb-2">
                {labels.validity}
              </p>
              <p className="t-nowrap body-text-sm subtitle-color font-w-500">
                {dayjs(selectedLicense?.attributes?.start_date).format("DD")}/
                {dayjs(selectedLicense?.attributes?.start_date)
                  .format("MMMM")
                  .charAt(0)
                  .toUpperCase()}
                {dayjs(selectedLicense?.attributes?.start_date)
                  .format("MMMM")
                  .slice(1)}
                /{dayjs(selectedLicense?.attributes?.start_date).format("YYYY")}{" "}
                - {dayjs(selectedLicense?.attributes?.end_date).format("DD")}/
                {dayjs(selectedLicense?.attributes?.end_date)
                  .format("MMMM")
                  .charAt(0)
                  .toUpperCase()}
                {dayjs(selectedLicense?.attributes?.end_date)
                  .format("MMMM")
                  .slice(1)}
                /{dayjs(selectedLicense?.attributes?.end_date).format("YYYY")}
              </p>
            </div>
            <div className="w-100">
              <p className="t-nowrap body-text-sm subtitle-color mr-4 mb-2">
                {labels.users_allowed_by_role}
              </p>
              <Table size="small" style={{ border: "1px #e0e0e0 solid" }}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{ color: "#666", fontSize: 14 }}
                    >
                      {labels.role}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ color: "#666", fontSize: 14 }}
                    >
                      {labels.allowed}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center" style={{ color: "#666" }}>
                      {labels.admins}
                    </TableCell>
                    <TableCell align="center" style={{ color: "#666" }}>
                      {selectedLicense?.attributes?.max_admins}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" style={{ color: "#666" }}>
                      {labels.users}
                    </TableCell>
                    <TableCell align="center" style={{ color: "#666" }}>
                      {selectedLicense?.attributes?.max_hosts}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" style={{ color: "#666" }}>
                      {labels.secondary_users}/{labels.unit}
                    </TableCell>
                    <TableCell align="center" style={{ color: "#666" }}>
                      {selectedLicense?.attributes?.max_seconday_users_by_unit}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center" style={{ color: "#666" }}>
                      {labels.security_guards}
                    </TableCell>
                    <TableCell align="center" style={{ color: "#666" }}>
                      {selectedLicense?.attributes?.max_security_guards}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Paper>
        </div>
      </Modal>
      <DesktopHeader title={labels.properties} />
      <DesktopBody hasFilters={true} filterData={filters.filterData}>
        <div className="w-100 h-100 px-8 pb-8 border-box">
          {isLoading ? (
            <div className="w-100 h-100 border-box flex align-items-center justify-content-center">
              <CircularProgress />
            </div>
          ) : (
            <div className="w-100 h-100 border-box flex gap-8">
              <div className="w-50 h-100 flex">
                <div className="w-100 h-100 pb-8 border-box">
                  {isLoading ? (
                    <div className="w-100 h-100 border-box flex align-items-center justify-content-center">
                      <CircularProgress />
                    </div>
                  ) : (
                    // <TableComponent
                    //   selectableRow={true}
                    //   data={tableDataToDisplay}
                    //   onPressRow={(id: number) => {
                    //     setSelectedAccessPointId(id);
                    //     setShowAccessPointDetails(true);
                    //   }}
                    //   page={page}
                    //   setPage={setPage}
                    //   rowsPerPage={rowsPerPage}
                    //   setRowsPerPage={setRowsPerPage}
                    //   total={totalInvitations}
                    //   tableHeight={"calc(100vh - 188px)"}
                    // />
                    <div className="w-100 border-box hidden flex flex-dir-col align-items-center justify-content-between gap-10">
                      {properties?.data.map((item, index) => (
                        <div
                          key={`${item?.id}-${index}`}
                          className="w-100 border-box hidden p-6"
                          style={{
                            backgroundColor: "white",
                            border: "1px #c4c4c4 solid",
                            borderRadius: 6,
                          }}
                        >
                          <div className="w-100 border-box flex align-items-center justify-content-between">
                            <p className="t-nowrap body-text-md mb-3 title-color font-w-700">
                              {item?.attributes?.name}
                            </p>
                            <div className="flex align-items-center gap-6">
                              <div className="flex align-items-center">
                                <IconifyIcon
                                  icon={"mdi:graph-areaspline-variant"}
                                  className="subtitle-color font-22 pointer"
                                  onClick={() => {
                                    setSelectedPropertyName(
                                      `${item?.attributes?.name}`
                                    );
                                    setShowInteligence(true);
                                  }}
                                />
                              </div>
                              <div className="flex align-items-center">
                                <IconifyIcon
                                  icon={"tabler:license"}
                                  className="subtitle-color font-22 pointer"
                                  onClick={() => {
                                    if (item?.attributes?.licence?.data) {
                                      setSelectedLicense(
                                        item?.attributes?.licence?.data
                                      );
                                      setSelectedPropertyName(
                                        `${item?.attributes?.name}`
                                      );
                                      setShowLicenseDetails(true);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="w-100 border-box flex align-items-center mt-6">
                            <p className="t-nowrap body-text-sm mb-3 mr-4">
                              {labels.type}:
                            </p>
                            <p className="t-nowrap body-text-sm mb-3 font-w-500">
                              {item?.attributes?.type === "Corporate" &&
                                labels.corporate}
                              {item?.attributes?.type === "Residential" &&
                                labels.residential}
                              {item?.attributes?.type === "Industrial" &&
                                labels.industrial}
                            </p>
                          </div>
                          <div className="w-100 border-box flex align-items-center">
                            <p className="t-nowrap body-text-sm mb-3 mr-4">
                              {labels.address}:
                            </p>
                            <p className="t-nowrap body-text-sm mb-3 font-w-500">
                              {item?.attributes?.address}
                            </p>
                          </div>
                          <div
                            className="w-100 border-box mt-6"
                            style={{ border: "1px #e0e0e0 solid" }}
                          >
                            <div
                              className="w-100 border-box flex align-items-center p-4"
                              style={{ borderBottom: "1px #e0e0e0 solid" }}
                            >
                              <div className="w-20 flex align-items-center justify-content-center">
                                <p className="body-text-xs font-w-500">
                                  {labels.admins}
                                </p>
                              </div>
                              <div className="w-20 flex align-items-center justify-content-center">
                                <p className="body-text-xs font-w-500">
                                  {labels.users}
                                </p>
                              </div>
                              <div className="w-30 flex align-items-center justify-content-center">
                                <p className="body-text-xs font-w-500">
                                  {labels.secondary_users}
                                </p>
                              </div>
                              <div className="w-30 flex align-items-center justify-content-center">
                                <p className="body-text-xs font-w-500">
                                  {labels.security_guards}
                                </p>
                              </div>
                            </div>
                            <div className="w-100 border-box flex align-items-center p-4">
                              <div className="w-20 flex align-items-center justify-content-center">
                                <p className="body-text-xs">
                                  {
                                    item?.attributes?.users?.data.filter(
                                      (item) =>
                                        item?.attributes?.role?.data?.id === 6
                                    ).length
                                  }
                                </p>
                              </div>
                              <div className="w-20 flex align-items-center justify-content-center">
                                <p className="body-text-xs">
                                  {
                                    item?.attributes?.users?.data.filter(
                                      (item) =>
                                        item?.attributes?.role?.data?.id === 3
                                    ).length
                                  }
                                </p>
                              </div>
                              <div className="w-30 flex align-items-center justify-content-center">
                                <p className="body-text-xs">
                                  {
                                    item?.attributes?.users?.data.filter(
                                      (item) =>
                                        item?.attributes?.role?.data?.id === 5
                                    ).length
                                  }
                                </p>
                              </div>
                              <div className="w-30 flex align-items-center justify-content-center">
                                <p className="body-text-xs">
                                  {
                                    item?.attributes?.users?.data.filter(
                                      (item) =>
                                        item?.attributes?.role?.data?.id === 4
                                    ).length
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="w-50 h-100flex  map-container">
                {isLoaded && (
                  <GoogleMap
                    mapContainerStyle={mapStyles}
                    onLoad={onMapLoad}
                    onUnmount={onUnmount}
                  >
                    {coordinates.map((coordinate: any, index: any) => (
                      <Marker key={index} position={coordinate} />
                    ))}
                  </GoogleMap>
                )}
              </div>
            </div>
          )}
        </div>
      </DesktopBody>
    </div>
  );
};

export default Properties;
