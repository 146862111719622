import React from "react";
import { useAppSelector } from "../app/hooks";
import { selectLabels } from "../slices/language";
import { selectUserRole } from "../slices/user";

const useMenu = () => {
  const labels = useAppSelector(selectLabels);
  const userRole = useAppSelector(selectUserRole);

  const [fixedMenu, setFixedMenu] = React.useState<
    | {
        id: string;
        label: string;
        icon: string;
        show: boolean;
        route: string;
        children: {
          label: any;
          route: string;
        }[];
      }[]
  >([]);

  const [openSubmenu, setOpenSubmenu] = React.useState<string[]>([]);

  const menu = React.useMemo(() => {
    return [
      {
        id: "properties",
        label: labels.properties,
        icon: "fa-solid:building",
        show: userRole === "ciso",
        route: "/properties",
        children: [],
      },
      {
        id: "access_points",
        label: labels.access_points,
        icon: "fa-solid:door-open",
        show: userRole === "ciso",
        route: "/access-points",
        children: [],
      },
      // {
      //   id: "access_groups",
      //   label: labels.access_groups,
      //   icon: "ri:door-lock-fill",
      //   show: userRole === "ciso",
      //   route: "/access-groups",
      //   children: [],
      // },
      {
        id: "admins",
        label: labels.admins,
        icon: "fa-solid:user-cog",
        show: userRole === "ciso",
        route: "/network-admins",
        children: [],
      },
      {
        id: "access_keys",
        label: labels.access_keys,
        icon: "fa-solid:envelope-open-text",
        show: userRole === "ciso" || userRole === "guard" ? false : true,
        route: "/access-keys",
        children: [],
      },
      {
        id: "logbook",
        label: labels.logbook,
        icon: "fa6-solid:book",
        show: userRole === "ciso" || userRole === "guard" ? false : true,
        route: "/logbook",
        children: [],
      },
      {
        id: "users",
        label: labels.users,
        icon: "fa-solid:users",
        show: userRole === "host",
        route: "/users",
        children: [],
      },
      {
        id: "settings",
        label: labels.settings,
        icon: "fa-solid:cog",
        show: userRole === "admin",
        route: "",
        children: [
          {
            label: labels.access_points,
            route: "/access-points",
          },
          {
            label: labels.units,
            route: "/units",
          },
          {
            label: labels.users,
            route: "/host-users",
          },
          {
            label: labels.security_guards,
            route: "/security-guards",
          },
          {
            label: labels.admins,
            route: "/network-admins",
          },
        ],
      },
    ];
  }, [labels, userRole]);

  const toggleSubMenu = (item: string) => {
    const newOpenSubmenu = [...openSubmenu];
    if (newOpenSubmenu.includes(item)) {
      const index = newOpenSubmenu.indexOf(item);
      newOpenSubmenu.splice(index, 1);
    } else {
      newOpenSubmenu.push(item);
    }
    setOpenSubmenu(newOpenSubmenu);
  };

  React.useEffect(() => {
    setFixedMenu(menu);
  }, [menu]);

  return { fixedMenu, toggleSubMenu, openSubmenu };
};

export default useMenu;
