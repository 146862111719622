import { configureStore } from "@reduxjs/toolkit";
import languageReducer from "../slices/language";
import userReducer from "../slices/user";
import tokenReducer from "../slices/token";

export const store = configureStore({
  reducer: {
    language: languageReducer,
    user: userReducer,
    token: tokenReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
