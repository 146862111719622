import {
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import React from "react";
import { useAppSelector } from "../../../app/hooks";
import { selectLabels } from "../../../slices/language";

interface NewInvitationVisitorDataProps {
  visitorName: string;
  setVisitorName: (visitorName: string) => void;
  countryCode: "+52" | "+1";
  setCountryCode: (countryCode: "+52" | "+1") => void;
  visitorPhone: string;
  setVisitorPhone: (visitorPhone: string) => void;
  visitorEmail: string;
  setVisitorEmail: (visitorEmail: string) => void;
  saveContact: boolean;
  setSaveContact: (saveContact: boolean) => void;
}

const NewInvitationVisitorData = ({
  visitorName,
  setVisitorName,
  countryCode,
  setCountryCode,
  visitorPhone,
  setVisitorPhone,
  visitorEmail,
  setVisitorEmail,
  saveContact,
  setSaveContact,
}: NewInvitationVisitorDataProps) => {
  const labels = useAppSelector(selectLabels);

  const countryCodeArray = [
    {
      label: "MX +52",
      value: "+52",
    },
    {
      label: "US +1",
      value: "+1",
    },
  ];

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setState: (state: boolean) => void
  ) => {
    setState(event.target.checked);
  };

  return (
    <div
      className="w-100 border-box hidden flex flex-dir-col align-items-center gap-6 p-8"
      style={{
        backgroundColor: "white",
        border: "1px #e0e0e0 solid",
        borderRadius: 6,
      }}
    >
      <div className="w-100">
        <p className="t-nowrap body-text-lg font-w-500 title-color mb-4">
          {labels.visitor_data}
        </p>
      </div>
      <div className="w-100 border-box flex align-items-center gap-10">
        <div className="w-50">
          <p className="t-nowrap body-text-xs mb-3">{labels.full_name} *</p>
          <TextField
            fullWidth
            placeholder={labels.enter_your_name}
            value={visitorName}
            size="small"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setVisitorName(event.target.value);
            }}
          />
        </div>
        <div className="w-50">
          <p className="t-nowrap body-text-xs mb-3">{labels.telephone} *</p>
          <div className="flex align-items-center justify-content-end gap-4">
            <FormControl style={{ width: 150 }}>
              <Select
                value={countryCode}
                onChange={(event: SelectChangeEvent) =>
                  setCountryCode(event.target.value as "+52" | "+1")
                }
                size="small"
              >
                {countryCodeArray.map((item, index) => (
                  <MenuItem key={`${index}-${item?.label}`} value={item?.value}>
                    {item?.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              fullWidth
              type="number"
              placeholder={labels.ten_digit_number}
              value={visitorPhone}
              size="small"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setVisitorPhone(event.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <div className="w-100 border-box flex align-items-center gap-10">
        <div className="w-50">
          <p className="t-nowrap body-text-xs mb-3">{labels.email}</p>
          <TextField
            fullWidth
            placeholder={"email@example.com"}
            value={visitorEmail}
            size="small"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setVisitorEmail(event.target.value);
            }}
          />
        </div>
        <div className="w-50">
          <p
            className="t-nowrap body-text-xs mb-3"
            style={{ color: "transparent" }}
          >
            ""
          </p>
          <div className="flex align-items-center justify-content-end gap-2">
            <p className="t-nowrap body-text-md">{labels.save_contact}</p>
            <Checkbox
              checked={saveContact}
              onChange={(e) => handleChange(e, setSaveContact)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewInvitationVisitorData;
