import { CircularProgress, Modal } from "@mui/material";
import React from "react";

interface LoadingModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const LoadingModal = ({ open, setOpen }: LoadingModalProps) => {
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div
        style={{
          width: "100%",
          height: "100%",
          border: "none",
          outline: "none",
        }}
        className="flex justify-content-center align-items-center"
      >
        <CircularProgress style={{ color: "white" }} />
      </div>
    </Modal>
  );
};

export default LoadingModal;
