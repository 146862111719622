import React from "react";
import { useAppSelector } from "../../../app/hooks";
import { selectLabels } from "../../../slices/language";
import { InputBase } from "@mui/material";
import IconifyIcon from "../../IconifyIcon";

interface SearchInputProps {
  isLoading?: boolean;
  value: string;
  setValue: (value: string) => void;
  size: "small" | "large";
}

const SearchInput = ({
  isLoading,
  value,
  setValue,
  size,
}: SearchInputProps) => {
  const labels = useAppSelector(selectLabels);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <div
      style={{
        display: "flex",
        boxSizing: "border-box",
        alignItems: "center",
        width: "100%",
        minWidth: 120,
        height: size === "small" ? 40 : 56,
        borderRadius: 4,
        borderColor: "#c4c4c4",
        borderStyle: "solid",
        borderWidth: 1,
        backgroundColor: "white",
      }}
    >
      <IconifyIcon
        icon={"fa-solid:search"}
        style={{ marginLeft: 8, color: "#666" }}
      />
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={`${labels.search}...`}
        onChange={handleChange}
        value={value}
        disabled={isLoading}
      />
    </div>
  );
};

export default SearchInput;
