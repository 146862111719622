import axios from "axios";
import { User } from "./models/user-model";
import { AccessKeyArray, AccessKeyRelation } from "./models/access-key-model";
import { AccessPointArray, AccessPointRelation } from "./models/access-points";
import { UnitArray } from "./models/units";
import { PhotoTypesArray } from "./models/photo-types-model";
import { PropertyArray } from "./models/property-model";
import { MediaResponse } from "./models/media-model";

export const instance = axios.create({
  baseURL: "https://strapi.passtrack.mx",
  timeout: 30000,
});

//LOGIN------------------------------------------------------->>

export interface LoginPayload {
  identifier: string;
  password: string;
}

export interface LoginResponse {
  jwt: string;
  user: User;
}

const loginRequest = async (params: LoginPayload) => {
  const { data }: { data: LoginResponse } = await instance.post(
    "/api/auth/local",
    params
  );

  return data;
};

//------------------------------------------------------------>>
//USERS------------------------------------------------------->>

const userByIdRequest = async (userId: number, token: string) => {
  const { data }: { data: User } = await instance.get(
    `/api/users/${userId}?populate=*`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return data;
};

const usersListRequest = async (
  searchQuery?: string,
  property?: number | null,
  unit?: number | null,
  role?: number,
  roleTwo?: number
) => {
  let initialStates = `populate=*`;
  if (searchQuery) {
    initialStates += `&filters[username][$containsi]=${searchQuery}`;
  }
  if (property) {
    initialStates += `&filters[property][id][$eq]=${property}`;
  }
  if (unit) {
    initialStates += `&filters[unit][id][$eq]=${unit}`;
  }
  if (role) {
    initialStates += `&filters[role][id][$in]=${role}`;
  }
  if (roleTwo) {
    initialStates += `&filters[role][id][$in]=${roleTwo}`;
  }
  const { data }: { data: User[] } = await instance.get(
    `/api/users?${initialStates}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return data;
};

export interface CreateUserPayload {
  username?: string;
  email?: string;
  phone?: string;
  unit?: number;
  property?: number[] | null;
  company?: number;
  password?: string;
  access_points?: number[];
  created_by_user?: string;
}

export interface UserConfirmationEmailPayload {
  username: string;
  role: number;
  email: string;
}

export interface UserCreatedResponse {
  user: User;
}

const createUser = async (params: CreateUserPayload) => {
  const { data }: { data: UserCreatedResponse } = await instance.post(
    `/api/auth/local/register`,
    params,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return data;
};

const addUserRole = async (userId: number, role: number) => {
  const { data }: { data: User } = await instance.put(
    `/api/users/${userId}`,
    {
      role,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return data;
};

const sendConfirmationEmail = async (params: UserConfirmationEmailPayload) => {
  const { data }: { data: User } = await instance.post(
    `/api/auth/send-email-confirmation`,
    params,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return data;
};

//------------------------------------------------------------>>
//ACCESS KEYS------------------------------------------------->>

const accessKeysRequest = async (page: number, pageSize: number) => {
  const { data }: { data: AccessKeyArray } = await instance.get(
    `/api/access-keys?populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return data;
};

const accessKeysByIdRequest = async (accessKeyId: number) => {
  const { data }: { data: AccessKeyRelation } = await instance.get(
    `/api/access-keys/${accessKeyId}?populate=*`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return data;
};

//------------------------------------------------------------>>
//ACCESS POINTS----------------------------------------------->>

const accessPointsRequest = async (
  page: number,
  pageSize: number,
  name?: string
) => {
  let initialStates = `populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}`;
  if (name) {
    initialStates += `&filters[name][$containsi]=${name}`;
  }
  const { data }: { data: AccessPointArray } = await instance.get(
    `/api/access-points?${initialStates}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return data;
};

const accessPointsCISORequest = async (
  companyId: number,
  propertyId: number,
  page: number,
  pageSize: number,
  name?: string
) => {
  let initialStates = `populate[0]=property.company&populate[1]=gates&populate[2]=photo_types&filters[property][company][id][$eq]=${companyId}&filters[property][id][$eq]=${propertyId}&pagination[page]=${page}&pagination[pageSize]=${pageSize}`;
  if (name) {
    initialStates += `&filters[name][$containsi]=${name}`;
  }
  const { data }: { data: AccessPointArray } = await instance.get(
    `/api/access-points?${initialStates}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return data;
};

const accessPointByIdRequest = async (accessPointId: number) => {
  const { data }: { data: AccessPointRelation } = await instance.get(
    `/api/access-points/${accessPointId}?populate=*`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return data;
};

export interface AccessPointPayload {
  data: {
    name?: string;
    photo_types?: number[];
  };
}

const updateAccessPoint = async (
  accessPointId: number,
  params: AccessPointPayload
) => {
  const { data }: { data: AccessPointRelation } = await instance.put(
    `/api/access-points/${accessPointId}`,
    params,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return data;
};

//------------------------------------------------------------>>
//UNITS------------------------------------------------------->>

const unitsRequest = async (
  property: number,
  page: number,
  pageSize: number
) => {
  const { data }: { data: UnitArray } = await instance.get(
    `/api/units?populate=*&filters[property][id][$eq]=${property}&pagination[page]=${page}&pagination[pageSize]=${pageSize}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return data;
};

//------------------------------------------------------------>>
//PHOTO TYPES------------------------------------------------->>

const photoTypesRequest = async () => {
  const { data }: { data: PhotoTypesArray } = await instance.get(
    `/api/photo-types?populate=*`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return data;
};

//------------------------------------------------------------>>
//PROPERTIES-------------------------------------------------->>

const propertiesByCompanyRequest = async (companyId: number, name?: string) => {
  let initialStates = `populate[0]=users.role&populate[1]=company&populate[2]=licence&populate[3]=units&populate[4]=access_points&filters[company][id][$eq]=${companyId}`;
  if (name) {
    initialStates += `&filters[name][$containsi]=${name}`;
  }
  const { data }: { data: PropertyArray } = await instance.get(
    `/api/properties?${initialStates}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );

  return data;
};

//------------------------------------------------------------>>
//UPLOAD------------------------------------------------------>>

export interface UploadMediaRequest {
  files: File;
  ref: string;
  refId: number;
  field: string;
}

const uploadMediaRequest = async (params: UploadMediaRequest) => {
  const { data }: { data: MediaResponse[] } = await instance.post(
    `/api/upload`,
    params,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    }
  );

  return data;
};

//------------------------------------------------------------>>

const api = {
  loginRequest,
  userByIdRequest,
  accessKeysRequest,
  accessKeysByIdRequest,
  usersListRequest,
  accessPointsRequest,
  unitsRequest,
  createUser,
  sendConfirmationEmail,
  addUserRole,
  accessPointByIdRequest,
  photoTypesRequest,
  propertiesByCompanyRequest,
  accessPointsCISORequest,
  uploadMediaRequest,
  updateAccessPoint,
};

export default api;
