import React from "react";
import DesktopHeader from "../../components/DesktopHeader";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import DesktopBody from "../../components/DesktopBody";
import TableComponent from "../../components/TableComponent";
import { TableData } from "../../components/TableComponent/TableComponent";
import dayjs, { Dayjs } from "dayjs";
import { CircularProgress } from "@mui/material";
import { DesktopFiltersProps } from "../../components/DesktopFilters/DesktopFilters";
import { selectUser } from "../../slices/user";
import DesktopDropView from "../../components/DesktopDropView";
import NewInvitationForm from "../../components/NewInvitationForm";
import DesktopInvitationDetails from "../../components/DesktopInvitationDetails";
import { accessMockData } from "../../mockdata/logbookMock";

const Logbook = () => {
  const labels = useAppSelector(selectLabels);
  const user = useAppSelector(selectUser);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [tableDataToDisplay, setTableDataToDisplay] =
    React.useState<TableData | null>(null);
  const [searchFilter, setSearchFilter] = React.useState<string>("");
  const [selectedAccessPoint, setSelectedAccessPoint] =
    React.useState<string>("0");
  const [selectedState, setSelectedState] = React.useState<
    "" | "entry" | "exit"
  >("");
  const [selectedDateFilter, setSelectedDateFilter] =
    React.useState<string>("all_dates");
  const [showAccessKeyDetails, setShowAccessKeyDetails] =
    React.useState<boolean>(false);
  const [newInvitation, setNewInvitation] = React.useState<boolean>(false);
  const [dateFilter, setDateFilter] = React.useState<Dayjs | null>(null);
  const [selectedInvitationId, setSelectedInvitationId] = React.useState<
    number | null
  >(null);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalInvitations, setTotalInvitations] = React.useState<number>(0);

  const logbookList = accessMockData;

  // const fixedPage = React.useMemo(() => {
  //   return page + 1;
  // }, [page]);

  const accessPointsArray = user?.access_points
    ? user.access_points.map((item) => {
        return {
          label: item?.name,
          value: `${item.id}`,
        };
      })
    : [];

  const datesFilterArray = [
    {
      label: labels.all_dates,
      value: "all_dates",
    },
    {
      label: labels.today,
      value: "today",
    },
    {
      label: labels.last_week,
      value: "last_week",
    },
    {
      label: labels.last_month,
      value: "last_month",
    },
    {
      label: labels.calendar,
      value: "calendar",
    },
  ];

  accessPointsArray.unshift({
    label: labels.all_access_points,
    value: "0",
  });

  const filters: DesktopFiltersProps = {
    filterData: [
      {
        type: "multidate",
        value: selectedDateFilter,
        setValue: setSelectedDateFilter,
        disabled: isLoading,
        tooltip: "",
        function: () => {},
        array: datesFilterArray,
        size: "small",
        icon: "",
        label: labels.access_points,
        multiDateValue: dateFilter,
        setMultiDateValue: setDateFilter,
      },
      {
        type: "selector",
        value: selectedAccessPoint,
        setValue: setSelectedAccessPoint,
        disabled: isLoading,
        tooltip: "",
        function: () => {},
        array: accessPointsArray,
        size: "small",
        icon: "",
        label: labels.access_points,
        multiDateValue: null,
        setMultiDateValue: () => {},
      },
      {
        type: "icon",
        value: selectedState,
        setValue: setSelectedState,
        disabled: isLoading,
        tooltip: labels.entries,
        function: () => {},
        array: [],
        size: "small",
        icon: "entypo:login",
        label: "entry",
        multiDateValue: null,
        setMultiDateValue: () => {},
      },
      {
        type: "icon",
        value: selectedState,
        setValue: setSelectedState,
        disabled: isLoading,
        tooltip: labels.exits,
        function: () => {},
        array: [],
        size: "small",
        icon: "entypo:log-out",
        label: "exit",
        multiDateValue: null,
        setMultiDateValue: () => {},
      },
      {
        type: "search",
        value: searchFilter,
        setValue: setSearchFilter,
        disabled: isLoading,
        tooltip: "",
        function: () => {},
        array: [],
        size: "small",
        icon: "",
        label: "",
        multiDateValue: null,
        setMultiDateValue: () => {},
      },
      {
        type: "function",
        value: "",
        setValue: () => {},
        disabled: isLoading,
        tooltip: labels.download_content,
        function: () => {},
        array: [],
        size: "small",
        icon: "fa-solid:file-download",
        label: "",
        multiDateValue: null,
        setMultiDateValue: () => {},
      },
    ],
  };

  React.useEffect(() => {
    getLogbookData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logbookList, labels]);

  const getLogbookData = () => {
    const dataForTable: TableData = {
      header: [
        {
          label: labels.date,
          isIcon: false,
          tooltipLabel: "",
        },
        {
          label: labels.visitor,
          isIcon: false,
          tooltipLabel: "",
        },
        {
          label: labels.host,
          isIcon: false,
          tooltipLabel: "",
        },
        {
          label: labels.unit,
          isIcon: false,
          tooltipLabel: "",
        },
        {
          label: labels.check_in_time,
          isIcon: false,
          tooltipLabel: "",
        },
        {
          label: labels.check_out_time,
          isIcon: false,
          tooltipLabel: "",
        },
        {
          label: labels.type_of_registration,
          isIcon: false,
          tooltipLabel: "",
        },
      ],
      body: logbookList.data.map((item) => {
        return {
          row: item?.id,
          cells: [
            {
              label:
                dayjs(
                  item?.attributes?.entrance_transaction?.data?.attributes
                    ?.datetime
                ).date() ===
                  dayjs(
                    item?.attributes?.exit_transaction?.data?.attributes
                      ?.datetime
                  ).date() &&
                dayjs(
                  item?.attributes?.entrance_transaction?.data?.attributes
                    ?.datetime
                ).month() ===
                  dayjs(
                    item?.attributes?.exit_transaction?.data?.attributes
                      ?.datetime
                  ).month()
                  ? [
                      {
                        mainLabel: dayjs(
                          item?.attributes?.entrance_transaction?.data
                            ?.attributes?.datetime
                        ).format("DD/MMMM"),
                        tooltipLabel: "",
                      },
                    ]
                  : [
                      {
                        mainLabel: dayjs(
                          item?.attributes?.entrance_transaction?.data
                            ?.attributes?.datetime
                        ).format("DD/MMMM"),
                        tooltipLabel: "",
                      },
                    ],
              isIcon: false,
              display: "flex-dir-row",
            },
            {
              label: [
                {
                  mainLabel: item?.attributes?.visitor,
                  tooltipLabel: "",
                },
              ],
              isIcon: false,
              display: "flex-dir-row",
            },
            {
              label: [
                {
                  mainLabel: item?.attributes?.user,
                  tooltipLabel: "",
                },
              ],
              isIcon: false,
              display: "flex-dir-row",
            },
            {
              label: [
                {
                  mainLabel: item?.attributes?.unit,
                  tooltipLabel: "",
                },
              ],
              isIcon: false,
              display: "flex-dir-col",
            },
            {
              label: [
                {
                  mainLabel: dayjs(
                    item?.attributes?.entrance_transaction?.data?.attributes
                      ?.datetime
                  ).format("h:mma"),
                  tooltipLabel: "",
                },
              ],
              isIcon: false,
              display: "flex-dir-col",
            },
            {
              label: [
                {
                  mainLabel: item?.attributes?.exit_transaction?.data
                    ? dayjs(
                        item?.attributes?.exit_transaction?.data?.attributes
                          ?.datetime
                      ).format("h:mma")
                    : "",
                  tooltipLabel: "",
                },
              ],
              isIcon: false,
              display: "flex-dir-col",
            },
            {
              label: item.attributes.authorized
                ? [
                    {
                      mainLabel: item?.attributes?.access_key_id
                        ? "mdi:envelope"
                        : "mdi:clipboard",
                      tooltipLabel: item?.attributes?.access_key_id
                        ? labels.invitation
                        : labels.register,
                    },
                  ]
                : [
                    {
                      mainLabel: "entypo:block",
                      tooltipLabel: labels.not_authorized_lb,
                    },
                    {
                      mainLabel: item?.attributes?.access_key_id
                        ? "mdi:envelope"
                        : "mdi:clipboard",
                      tooltipLabel: item?.attributes?.access_key_id
                        ? labels.invitation
                        : labels.register,
                    },
                  ],
              isIcon: true,
              display: "flex-dir-row",
            },
          ],
        };
      }),
    };
    setTableDataToDisplay(dataForTable);
  };

  return (
    <div className="w-100">
      <DesktopHeader title={labels.logbook} />
      <DesktopBody hasFilters={true} filterData={filters.filterData}>
        <div className="w-100 h-100 px-8 pb-8 border-box">
          {isLoading ? (
            <div className="w-100 h-100 border-box flex align-items-center justify-content-center">
              <CircularProgress />
            </div>
          ) : (
            <TableComponent
              selectableRow={true}
              data={tableDataToDisplay}
              onPressRow={(id: number) => {
                setSelectedInvitationId(id);
                setShowAccessKeyDetails(true);
              }}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              total={totalInvitations}
              tableHeight={"calc(100vh - 188px)"}
            />
          )}
        </div>
      </DesktopBody>
      <DesktopDropView
        showView={showAccessKeyDetails}
        setShowView={setShowAccessKeyDetails}
        content={
          <div className="flex h-100 border-box" style={{ width: 500 }}>
            <div className="w-100 h-100 border-box p-8 scroll">
              <DesktopInvitationDetails invitationId={selectedInvitationId} />
            </div>
          </div>
        }
        extraFunc={false}
        title={labels.invitation_details}
      />
      <DesktopDropView
        showView={newInvitation}
        setShowView={setNewInvitation}
        content={
          <div className="flex h-100 border-box">
            <div className="h-100 border-box p-8 scroll">
              <NewInvitationForm />
            </div>
          </div>
        }
        extraFunc={false}
        title={labels.new_invitation}
      />
    </div>
  );
};

export default Logbook;
