import { CircularProgress, Divider } from "@mui/material";
import { QRCodeCanvas } from "qrcode.react";
import React from "react";
import api from "../../api";
import { AccessKeyRelation } from "../../api/models/access-key-model";
import { useAppSelector } from "../../app/hooks";
import { selectLabels } from "../../slices/language";
import dayjs from "dayjs";
import Logo from "../Logo";
import "./desktop-invitation-details.css";
import IconifyIcon from "../IconifyIcon";

interface DesktopInvitationDetailsProps {
  invitationId: number | null;
}

const DesktopInvitationDetails = ({
  invitationId,
}: DesktopInvitationDetailsProps) => {
  const labels = useAppSelector(selectLabels);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [accessKey, setAccessKey] = React.useState<AccessKeyRelation | null>(
    null
  );
  const [isSameDate, setIsSameDate] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (invitationId) {
      getInvitationData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitationId]);

  const getInvitationData = async () => {
    if (invitationId) {
      try {
        setIsLoading(true);
        const response = await api.accessKeysByIdRequest(invitationId);
        if (response) {
          setAccessKey(response);
          const sameDate =
            dayjs(response?.data?.attributes?.valid_from).date() ===
              dayjs(response?.data?.attributes?.valid_until).date() &&
            dayjs(response?.data?.attributes?.valid_from).month() ===
              dayjs(response?.data?.attributes?.valid_until).month();
          setIsSameDate(sameDate);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };
  return (
    <div className="w-100 border-box hidden">
      {isLoading ? (
        <div className="w-100 flex align-items-center justify-content-center mt-12">
          <CircularProgress />
        </div>
      ) : (
        <div className="w-100 border-box flex flex-dir-col gap-4">
          <div
            className="w-100 border-box flex flex-dir-col align-items-center gap-6 p-8"
            style={{
              backgroundColor: "white",
              border: "1px #e0e0e0 solid",
              borderRadius: 6,
            }}
          >
            <div className="w-100 border-box">
              <div className="w-100 flex align-items-center justify-content-center my-10">
                <Logo
                  type={"passTrackLogo"}
                  alt="passtrack"
                  className="inv-logo"
                />
              </div>
              <div className="w-100 flex justify-content-center mb-10">
                <QRCodeCanvas
                  value={`${accessKey?.data?.attributes?.uuid}`}
                  size={200}
                />
              </div>
              {/* {accessKey?.data?.attributes?.one_time_visitor && (
                <p className="body-text-xl font-w-500">
                  {
                    accessKey?.data?.attributes?.one_time_visitor?.data
                      ?.attributes?.name
                  }
                </p>
              )} */}
              {accessKey?.data?.attributes?.verified_visitor && (
                <p className="body-text-xl font-w-500">
                  {
                    accessKey?.data?.attributes?.verified_visitor?.data
                      ?.attributes?.username
                  }
                </p>
              )}
              <p className="body-text-md mt-2 mb-4">
                {labels.authorized_access_by}:{" "}
                {
                  accessKey?.data?.attributes?.created?.data?.attributes
                    ?.username
                }
              </p>
              <Divider />
              <p className="body-text-md mt-4 mb-2">
                {accessKey?.data?.attributes?.reason_for_visit}
              </p>
              <p className="body-text-md mb-4">
                {isSameDate
                  ? `${dayjs(accessKey?.data?.attributes?.valid_from)
                      .format("MMMM DD")
                      .charAt(0)
                      .toUpperCase()}${dayjs(
                      accessKey?.data?.attributes?.valid_from
                    )
                      .format("MMMM DD")
                      .slice(1)}, ${dayjs(
                      accessKey?.data?.attributes?.valid_from
                    ).format("h:mma")} ${labels.to_invitation_date} ${dayjs(
                      accessKey?.data?.attributes?.valid_until
                    ).format("h:mma")}`
                  : `${dayjs(accessKey?.data?.attributes?.valid_from)
                      .format("MMMM DD")
                      .charAt(0)
                      .toUpperCase()}${dayjs(
                      accessKey?.data?.attributes?.valid_from
                    )
                      .format("MMMM DD")
                      .slice(1)}, ${dayjs(
                      accessKey?.data?.attributes?.valid_from
                    ).format("h:mma")} - ${dayjs(
                      accessKey?.data?.attributes?.valid_until
                    )
                      .format("MMMM DD")
                      .charAt(0)
                      .toUpperCase()}${dayjs(
                      accessKey?.data?.attributes?.valid_until
                    )
                      .format("MMMM DD")
                      .slice(1)}, ${dayjs(
                      accessKey?.data?.attributes?.valid_until
                    ).format("h:mma")}`}
              </p>
            </div>
          </div>
          {accessKey?.data?.attributes?.access_points?.data &&
            accessKey?.data?.attributes?.access_points?.data.length > 0 &&
            accessKey?.data?.attributes?.access_points?.data.map(
              (item, index) => (
                <div
                  key={`${index}-${item?.id}`}
                  className="w-100 border-box flex flex-dir-col align-items-center gap-6 p-8"
                  style={{
                    backgroundColor: "white",
                    border: "1px #e0e0e0 solid",
                    borderRadius: 6,
                  }}
                >
                  <div className="w-100 flex align-items-center">
                    <div className="flex flex-dir-col align-items-center pointer">
                      <IconifyIcon
                        icon={"mingcute:location-2-fill"}
                        style={{ fontSize: 24 }}
                        className="subtitle-color mb-1"
                      />
                      <p className="subtitle-color font-12 font-w-500 uppercase">
                        {labels.map}
                      </p>
                    </div>
                    <Divider orientation="vertical" flexItem className="mx-8" />
                    <div>
                      <p className="body-text-md font-w-500 mb-2">
                        {item?.attributes?.vanue_name}
                      </p>
                      <p className="body-text-sm mb-2">
                        {item?.attributes?.address}
                      </p>
                      <p className="body-text-sm">{item?.attributes?.name}</p>
                    </div>
                  </div>
                </div>
              )
            )}
        </div>
      )}
    </div>
  );
};

export default DesktopInvitationDetails;
