import { Box, Button, IconButton, Modal } from "@mui/material";
import React from "react";
import IconifyIcon from "../../IconifyIcon";
import { useAppSelector } from "../../../app/hooks";
import { selectLabels } from "../../../slices/language";
import { selectUser, selectUserRole } from "../../../slices/user";
import "./user-modal.css";

const style = {
  position: "absolute" as "absolute",
  boxSizing: "border-box",
  minWidth: 350,
  top: 60,
  right: 16,
  overflow: "scroll",
  borderRadius: "20px",
  bgcolor: "#f0f0f0",
  boxShadow: 3,
  outline: 0,
  border: "1px #dfdfdf solid",
  p: 2,
};

interface UserModalProps {
  showUserDetails: boolean;
  setShowUserDetails: (showUserDetails: boolean) => void;
  onLogout: () => void;
}

const UserModal = ({
  showUserDetails,
  setShowUserDetails,
  onLogout,
}: UserModalProps) => {
  const labels = useAppSelector(selectLabels);
  const user = useAppSelector(selectUser);
  const userRole = useAppSelector(selectUserRole);

  const userInitial = user?.username.charAt(0);
  const userRoleValue =
    userRole === "ciso"
      ? labels.ciso
      : userRole === "admin"
      ? labels.property_manager
      : userRole === "secondary_host"
      ? labels.secondary_user
      : userRole === "host"
      ? labels.main_user
      : labels.security_guard;

  return (
    <Modal
      open={showUserDetails}
      onClose={() => setShowUserDetails(false)}
      slotProps={{ backdrop: { style: { backgroundColor: "transparent" } } }}
    >
      <Box sx={style}>
        <div className="w-100 flex flex-dir-col align-items-center border-box">
          <div className="w-100 flex align-items-center justify-content-between border-box px-6">
            <div style={{ width: 22 }} />
            <p className="body-text-md font-w-500">{user?.username}</p>
            <div style={{ width: 22 }}>
              <IconButton onClick={() => setShowUserDetails(false)}>
                <IconifyIcon icon={"icon-park-outline:close"} />
              </IconButton>
            </div>
          </div>
          <p className="body-text-xs mt-3 uppercase">{user?.company?.name}</p>
          <p className="body-text-xs mt-1">{userRoleValue}</p>
          <div className="user-photo-container mt-10">
            <p style={{ color: "white", fontWeight: 400, fontSize: 40 }}>
              {userInitial}
            </p>
            <div className="edit-user-photo">
              <IconifyIcon
                icon={"ic:round-edit"}
                style={{ fontSize: 16, color: "#8a8c91" }}
              />
            </div>
          </div>
          <Button
            style={{ width: 200, fontSize: 12 }}
            className="mt-10"
            variant="contained"
            disableElevation
          >
            {labels.manage_your_account}
          </Button>
          <Button
            style={{ width: 200, fontSize: 12 }}
            className="mt-6"
            variant="text"
            disableElevation
            startIcon={<IconifyIcon icon={"material-symbols:logout"} />}
            onClick={onLogout}
          >
            {labels.log_out}
          </Button>
          <div className="w-100 flex align-items-center justify-content-center">
            <p className="link-text mt-12">{labels.privacy_policy}</p>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default UserModal;
