import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import useMenu from "../../utils/menu";
import IconifyIcon from "../IconifyIcon";
import Logo from "../Logo";
import "./desktop-layout.css";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  selectLabels,
  selectLanguage,
  setLanguage,
} from "../../slices/language";

const DesktopLayout = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const labels = useAppSelector(selectLabels);
  const currentLanguage = useAppSelector(selectLanguage);
  const { fixedMenu, toggleSubMenu, openSubmenu } = useMenu();

  const [selectedLanguage, setSelectedLanguage] = React.useState<string>(
    currentLanguage.includes("es") ? "es" : "en"
  );

  const languajes = [
    {
      label: labels.spanish,
      value: "es",
    },
    {
      label: labels.english,
      value: "en",
    },
  ];

  const handleLanguageChange = (event: SelectChangeEvent) => {
    const newLanguage = event.target.value as string;
    console.log("newLanguage", newLanguage);
    setSelectedLanguage(newLanguage);
    dispatch(setLanguage(newLanguage));
  };

  return (
    <div className="w-100 flex" style={{ position: "relative" }}>
      <nav
        className="left-menu p-8 border-box vh-100 scroll"
        style={{ position: "fixed" }}
      >
        <div className="logo-container">
          <Logo type={"passTrackLogo"} alt="passtrack" className="menu-logo" />
        </div>
        <div style={{ marginTop: "120px", marginBottom: "60px" }}>
          {fixedMenu.map((item, index) => (
            <div key={`${index}-${item?.label}`}>
              {item.show &&
                (item.children.length > 0 ? (
                  <>
                    <div
                      className={
                        openSubmenu.includes(item.id)
                          ? `plegable-menu-link justify-content-between pointer`
                          : `menu-link justify-content-between pointer`
                      }
                      onClick={() => toggleSubMenu(item.id)}
                    >
                      <div className="flex align-items-center">
                        <IconifyIcon icon={item?.icon} />
                        <p className="ml-4 font-w-400 select-none">
                          {item?.label}
                        </p>
                      </div>
                      {openSubmenu.includes(item.id) ? (
                        <IconifyIcon icon={"fa6-solid:angle-down"} />
                      ) : (
                        <IconifyIcon icon={"fa6-solid:angle-right"} />
                      )}
                    </div>
                    {openSubmenu.includes(item.id) && (
                      <>
                        {item.children.map((child, index) => (
                          <Link
                            key={`${index}-${child.label}`}
                            to={child?.route}
                            style={{ textDecoration: "none" }}
                          >
                            <div
                              className={`menu-link ${
                                location.pathname === child?.route
                                  ? "selected"
                                  : ""
                              }`}
                              style={{ paddingLeft: 24 }}
                            >
                              {location.pathname === child?.route ? (
                                <IconifyIcon
                                  icon={"fa6-regular:circle-dot"}
                                  style={{ fontSize: 14 }}
                                />
                              ) : (
                                <IconifyIcon
                                  icon={"fa6-regular:circle"}
                                  style={{ fontSize: 14 }}
                                />
                              )}
                              <p className="ml-4 font-w-400 select-none">
                                {child?.label}
                              </p>
                            </div>
                          </Link>
                        ))}
                      </>
                    )}
                  </>
                ) : (
                  <Link to={item?.route} style={{ textDecoration: "none" }}>
                    <div
                      className={`menu-link ${
                        location.pathname === item?.route ? "selected" : ""
                      }`}
                    >
                      <IconifyIcon icon={item?.icon} />
                      <p className="ml-4 font-w-400 select-none">
                        {item?.label}
                      </p>
                    </div>
                  </Link>
                ))}
            </div>
          ))}
        </div>
        <div
          className="footer-menu-container pl-8"
          style={{ background: "#f0f0f0" }}
        >
          <IconifyIcon
            icon={"material-symbols:language"}
            style={{ fontSize: 20, marginRight: 8 }}
          />
          <FormControl variant="standard" sx={{ minWidth: 120 }} size="small">
            <Select value={selectedLanguage} onChange={handleLanguageChange}>
              {languajes.map((item, index) => (
                <MenuItem key={`${index}-${item?.value}`} value={item?.value}>
                  {item?.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </nav>
      <div
        className="outlet-container border-box vh-100 scroll"
        style={{
          backgroundColor: "white",
          position: "fixed",
          right: 0,
        }}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default DesktopLayout;
