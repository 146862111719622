import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Login";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { getUser, selectUserRole } from "../slices/user";
import DesktopLayout from "../components/DesktopLayout";
import AccessKeys from "./AccessKeys";
import Users from "./Users";
import { getToken, selectToken } from "../slices/token";
import Logbook from "./Logbook";
import AccessPoints from "./AccessPoints";
import Units from "./Units";
import HostUsers from "./HostUsers";
import SecurityGuards from "./SecurityGuards";
import NetworkAdmins from "./NetworkAdmins";
import Properties from "./Properties";
import AccessGroups from "./AccessGroups";

const AppRouter = () => {
  const token = useAppSelector(selectToken);
  const userRole = useAppSelector(selectUserRole);
  const dispatch = useAppDispatch();

  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);

  console.log("token", token);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    dispatch(getToken());
    dispatch(getUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  if (!token) {
    return (
      <>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </>
    );
  }

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<DesktopLayout />}>
            <Route path="/access-keys" element={<AccessKeys />} />
            <Route path="/logbook" element={<Logbook />} />
            <Route path="/users" element={<Users />} />
            <Route path="/access-points" element={<AccessPoints />} />
            <Route path="/units" element={<Units />} />
            <Route path="/host-users" element={<HostUsers />} />
            <Route path="/security-guards" element={<SecurityGuards />} />
            <Route path="/network-admins" element={<NetworkAdmins />} />
            <Route path="/properties" element={<Properties />} />
            <Route path="/access-groups" element={<AccessGroups />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default AppRouter;
