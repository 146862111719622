import React from "react";
import "./desktop-drop-view.css";
import IconifyIcon from "../IconifyIcon";
import { IconButton } from "@mui/material";

interface DesktopDropViewProps {
  showView: boolean;
  setShowView: (showView: boolean) => void;
  content: any;
  extraFunc: boolean;
  extraFuncElement?: any;
  title: string;
  onCLoseFunc?: () => void;
}

const DesktopDropView = ({
  showView,
  setShowView,
  content,
  extraFunc,
  extraFuncElement,
  title,
  onCLoseFunc,
}: DesktopDropViewProps) => {
  return (
    <>
      <div
        className="main-background"
        style={{
          backgroundColor: showView ? "rgba(0, 0, 0, 0.4)" : "rgba(0, 0, 0, 0)",
          transition: "background-color 0.4s ease",
          pointerEvents: showView ? "all" : "none",
        }}
        onClick={() => {
          setShowView(false);
          !!onCLoseFunc && onCLoseFunc();
        }}
      />
      <div
        className="drop-view-container"
        style={{
          boxShadow: showView ? `2px 0px 6px 4px rgba(0,0,0,0.3)` : "",
          transform: showView ? "translateX(0)" : "translateX(100%)",
          transition: "transform 0.4s ease",
        }}
      >
        <div className="header-drop-view px-8 flex align-items-center">
          <IconButton
            onClick={() => {
              setShowView(false);
              !!onCLoseFunc && onCLoseFunc();
            }}
          >
            <IconifyIcon
              icon={"fluent-mdl2:double-chevron-right-8"}
              style={{ fontSize: 20 }}
            />
          </IconButton>
        </div>
        <div className="header-two-drop-view px-8">
          <p className="main-title">{title}</p>
          {extraFunc && (
            <div className="flex align-items-center border-box">
              {extraFuncElement}
            </div>
          )}
        </div>
        <div className="drop-view-body w-100 border-box">{content}</div>
      </div>
    </>
  );
};

export default DesktopDropView;
