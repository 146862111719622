import React from "react";
import LOGOS from "./logos";

interface LogoProps {
  type: string;
  alt?: string;
  className?: string;
}

const Logo = ({ type, alt, className }: LogoProps) => {
  return <img src={LOGOS[type]} alt={alt || ""} className={className || ""} />;
};

export default Logo;
