import React, { ReactNode } from "react";
import "./desktop-body.css";
import DesktopFilters from "../DesktopFilters";
import { Dayjs } from "dayjs";

interface DesktopBodyProps {
  hasFilters: boolean;
  children: ReactNode;
  filterData:
    | {
        type:
          | "search"
          | "date"
          | "multidate"
          | "selector"
          | "autocomplete"
          | "icon"
          | "function";
        value: any;
        setValue: (value: any) => void;
        disabled: boolean;
        tooltip: string;
        function: () => void;
        array: { label: string; value: any }[];
        size: "small" | "large";
        icon: string;
        label: string;
        multiDateValue: Dayjs | null;
        setMultiDateValue: (multiDateValue: Dayjs | null) => void;
      }[]
    | null;
}

const DesktopBody = ({
  hasFilters,
  children,
  filterData,
}: DesktopBodyProps) => {
  return (
    <div className="desktop-body-container">
      {hasFilters && <DesktopFilters filterData={filterData} />}
      <div
        className={`dektop-body-content-container ${
          hasFilters && "dektop-body-content-container-filters"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default DesktopBody;
