import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers";

interface DatePickerCustomProps {
  value: Dayjs | null;
  setValue: (value: Dayjs | null) => void;
}

const DatePickerCustom = ({ value, setValue }: DatePickerCustomProps) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={value}
        onChange={(newValue) => setValue(newValue)}
        slotProps={{
          textField: { size: "small", fullWidth: true },
          openPickerButton: {
            size: "small",
          },
        }}
        format="DD/MM/YYYY"
      />
      {/* <DateTimePicker
        value={value}
        onChange={(newValue) => setValue(newValue)}
        slotProps={{
          textField: { size: "small", fullWidth: true },
          openPickerButton: {
            size: "small",
          },
        }}
        format="DD/MM/YYYY"
      /> */}
    </LocalizationProvider>
  );
};

export default DatePickerCustom;
