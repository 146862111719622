import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import roles from "../../utils/roles";
import { User } from "../../api/models/user-model";

export interface UserState {
  user: User | null;
}

const initialState: UserState = {
  user: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUser: (state) => {
      const newState = { ...state };
      const currentUser = localStorage.getItem("user");
      if (currentUser) {
        newState.user = JSON.parse(currentUser);
      }
      return newState;
    },
    setUser: (state, action) => {
      const newState = { ...state };
      newState.user = action.payload;
      // remove when user info endpoint available
      localStorage.setItem("user", JSON.stringify(action.payload));
      return newState;
    },
    resetUser: (state) => {
      const newState = { ...state };
      newState.user = null;
      localStorage.removeItem("user");
      return newState;
    },
  },
});

export const { setUser, getUser, resetUser } = userSlice.actions;

export const selectUser = (state: RootState) => state?.user?.user;
export const selectUserRole = (state: RootState) =>
  roles[state?.user?.user?.role?.name || ""];

export default userSlice.reducer;
